const BlueLinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill="#0068FF"
      fillRule="evenodd"
      stroke="#0068FF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.6}
      d="M19.265 4.735a4.543 4.543 0 0 0-6.432 0l-1.652 1.651a.6.6 0 1 0 .849.849l1.652-1.652a3.342 3.342 0 0 1 4.735 0 3.343 3.343 0 0 1 0 4.736l-2.558 2.557a3.342 3.342 0 0 1-4.735 0 .6.6 0 0 0-.848.848 4.542 4.542 0 0 0 6.432 0l2.557-2.557a4.543 4.543 0 0 0 0-6.432Zm-5.54 5.54a4.543 4.543 0 0 0-6.433 0l-2.558 2.557a4.542 4.542 0 0 0 0 6.432 4.542 4.542 0 0 0 6.433 0l1.556-1.555a.6.6 0 1 0-.849-.849l-1.556 1.556a3.342 3.342 0 0 1-4.735 0 3.342 3.342 0 0 1 0-4.735l2.557-2.558a3.343 3.343 0 0 1 4.736 0 .6.6 0 1 0 .848-.848Z"
      clipRule="evenodd"
    />
  </svg>
);

export default BlueLinkIcon;
