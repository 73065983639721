import React from "react";
import { useRecoilValue } from "recoil";

import { loadingState } from "states/loadingState";
import Spin from "components/spin";
import "./style.scss";

const Loading: React.FC = () => {
  const isLoading = useRecoilValue(loadingState);

  if (!isLoading) return null;
  return (
    <div className="loading-screen">
      <Spin isDanger></Spin>
    </div>
  );
};

export default Loading;
