const Warning = () => (
  <svg
    width={128}
    height={128}
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.3}
      d="M64 0C99.3462 0 128 28.6538 128 64C128 99.3462 99.3462 128 64 128C28.6538 128 0 99.3462 0 64C0 28.6538 28.6538 0 64 0Z"
      fill="url(#paint0_linear_4399_2676)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.3218 39.2783C60.8454 34.9072 67.1545 34.9072 69.6781 39.2783L94.4389 82.1652C96.9625 86.5362 93.808 92 88.7607 92H39.2392C34.1919 92 31.0374 86.5362 33.561 82.1652L58.3218 39.2783Z"
      fill="url(#paint1_linear_4399_2676)"
    />
    <path
      d="M61.1948 54.2008C61.1426 52.6702 62.3692 51.4011 63.9006 51.4011H64.0997C65.6311 51.4011 66.8577 52.6702 66.8055 54.2008L66.2394 70.8056C66.2039 71.8474 65.3491 72.6736 64.3067 72.6736H63.6936C62.6512 72.6736 61.7964 71.8474 61.7609 70.8056L61.1948 54.2008Z"
      fill="black"
    />
    <path
      d="M67.1594 79.5227C67.1594 81.2676 65.7449 82.682 64.0001 82.682C62.2553 82.682 60.8408 81.2676 60.8408 79.5227C60.8408 77.7779 62.2553 76.3635 64.0001 76.3635C65.7449 76.3635 67.1594 77.7779 67.1594 79.5227Z"
      fill="black"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4399_2676"
        x1={42.9061}
        y1={-18.6415}
        x2={79.1937}
        y2={109.812}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E78B00" />
        <stop offset={1} stopColor="#FFECA9" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4399_2676"
        x1={64.0001}
        y1={98.0379}
        x2={64.0001}
        y2={58.079}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1A104" />
        <stop offset={1} stopColor="#FFB525" />
      </linearGradient>
    </defs>
  </svg>
);

export default Warning;
