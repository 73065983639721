import './style.scss';

const ManageBenefitItem: React.FC<{
  icon: React.ReactNode;
  content: string;
}> = ({ icon, content }) => {
  return (
    <li className={`manage-benefit-item`}>
      <div className={`manage-benefit-item__info`}>
        <div className={`manage-benefit-item__info__icon-container`}>
          {icon}
        </div>
        <p className={`manage-benefit-item__info__text`}>{content}</p>
      </div>
    </li>
  );
};

export default ManageBenefitItem;
