import type { IconProps } from "./model";

const BlueCatalogIcon = ({}: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill="#0068FF"
      fillRule="evenodd"
      stroke="#0068FF"
      strokeWidth={0.6}
      d="m20.283 7.237-3.897-2.05-8.327 4.38 3.196 1.68a1.6 1.6 0 0 0 1.49 0l7.575-3.984a1.336 1.336 0 0 0-.037-.026Zm-7.538-3.965 2.352 1.237L6.77 8.89 3.68 7.263l.037-.026 7.538-3.965a1.6 1.6 0 0 1 1.49 0Zm8.059 5.093-7.5 3.945a2.8 2.8 0 0 1-.704.256v8.282c.049-.02.096-.042.143-.066l7.418-3.894a1.2 1.2 0 0 0 .643-1.062V8.365ZM11.4 20.848v-8.281a2.8 2.8 0 0 1-.704-.257l-7.5-3.945v7.46c0 .447.247.856.642 1.063l7.418 3.894c.047.024.095.046.144.066Zm9.493-14.646a2.405 2.405 0 0 0-.174-.1L13.115 2.11a2.4 2.4 0 0 0-2.23 0L3.28 6.101c-.06.032-.118.065-.175.101-.118.064-.22.142-.306.231a2.4 2.4 0 0 0-.804 1.793v7.6a2.4 2.4 0 0 0 1.285 2.125l7.418 3.893a2.8 2.8 0 0 0 2.602 0l7.418-3.893a2.4 2.4 0 0 0 1.285-2.125v-7.6a2.4 2.4 0 0 0-.805-1.793 1.212 1.212 0 0 0-.306-.23Z"
      clipRule="evenodd"
    />
  </svg>
);

export default BlueCatalogIcon;
