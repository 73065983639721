import BlueButton from "components/blue-button";
import DowngradeBasicFormPopup from "components/downgrade-basic-form-popup";
import ManageBenefitItem from "components/manage-benefit-item";
import { DATA_RESULT_MODAL } from "constant/data-result-modal";
import { useResultModal } from "hooks/useResultModal";
import BlueAutoReplyIcon from "icons/BlueAutoReplyIcon";
import BlueCatalogIcon from "icons/BlueCatalogIcon";
import BlueGroupIcon from "icons/BlueGroupIcon";
import BlueLinkIcon from "icons/BlueLinkIcon";
import BlueQuickReplyIcon from "icons/BlueQuickReplyIcon";
import CancelBasicIcon from "icons/CancelBasicIcon";
import InfoCircleIcon from "icons/InfoCircleIcon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import home from "services/home";
import { getDateAfterOneMonth, isPC } from "utils/helper";

const owlClass = "manage-basic";

const DISADVANTAGE_DOWNGRADE = [
  {
    icon: <BlueGroupIcon />,
    contentKey: "community-loss-basic-package",
  },

  {
    icon: <BlueQuickReplyIcon />,
    contentKey: "fast-message-loss-basic-package",
  },

  {
    icon: <BlueAutoReplyIcon />,
    contentKey: "auto-message-loss-basic-package",
  },
  {
    icon: <BlueLinkIcon />,
    contentKey: "link-loss-basic-package",
  },
  {
    icon: <BlueCatalogIcon />,
    contentKey: "category-loss-basic-package",
  },
] as const;

const DowngradeBasic: React.FC<{
  setVisibleDownGradeBottomSheet: (visible: boolean) => void;
}> = ({ setVisibleDownGradeBottomSheet }) => {
  const { setInfoResultModal } = useResultModal();

  const [showFormPopup, setShowFormPopup] = useState(false);
  const { t } = useTranslation();

  const continueBasic = () => {
    if (window.ZJSBridge) {
      window.ZJSBridge.H5.changeTitleHeader(t("manage-basic"));
    }
    setInfoResultModal({
      visible: true,
      info: DATA_RESULT_MODAL.CONTINUE_BASIC,
    });
    home.trackingLog({
      flow: "Downgrade",
      action: "click_keep_basic_downgrade_screen",
    });
  };

  const downgradeBasic = () => {
    if (isPC()) {
      setShowFormPopup(true);
    } else {
      setVisibleDownGradeBottomSheet(true);
    }

    home.trackingLog({
      flow: "Downgrade",
      action: "click_cancel_basic_downgrade_screen",
    });
  };

  if (showFormPopup) {
    return (
      <DowngradeBasicFormPopup
        setVisible={setShowFormPopup}
      ></DowngradeBasicFormPopup>
    );
  }

  return (
    <div className={` ${owlClass}__bg `}>
      <div className={`${owlClass}__container`}>
        <div className={`${owlClass}__img-title`}>
          <CancelBasicIcon></CancelBasicIcon>
        </div>
        <h1 className={`${owlClass}__title`}>{t("title-downgrade-basic")}</h1>
        <ul className={`${owlClass}__list-item`}>
          {DISADVANTAGE_DOWNGRADE.map((item, index) => (
            <ManageBenefitItem
              key={index}
              icon={item.icon}
              content={t(item.contentKey)}
            ></ManageBenefitItem>
          ))}
        </ul>

        <div className={`${owlClass}__notice`}>
          <div>
            <InfoCircleIcon></InfoCircleIcon>
          </div>
          <p
            className={`${owlClass}__notice-text`}
            dangerouslySetInnerHTML={{
              __html: t("disclaimer-cancel-basic", {
                date: getDateAfterOneMonth(Date.now()),
              }),
            }}
          ></p>
        </div>

        <div className={`${owlClass}__footer`}>
          <BlueButton
            content={t("continue-basic-plan")}
            customStyleContainer={{
              width: "100%",
            }}
            handleTouchButton={continueBasic}
          ></BlueButton>
          <button
            className={`${owlClass}__footer__cancel-btn`}
            onClick={downgradeBasic}
          >
            {t("cancel-anyway")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DowngradeBasic;
