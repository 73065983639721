const Alert = () => (
  <svg
    width={128}
    height={128}
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={0.3}
      d="M64 0.00012207C99.3462 0.00012207 128 28.6539 128 64.0001C128 99.3463 99.3462 128 64 128C28.6538 128 0 99.3463 0 64.0001C0 28.6539 28.6538 0.00012207 64 0.00012207Z"
      fill="url(#paint0_linear_4181_15309)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.3218 39.2783C60.8454 34.9072 67.1545 34.9072 69.6781 39.2783L94.4389 82.1652C96.9625 86.5362 93.808 92 88.7607 92H39.2392C34.1919 92 31.0374 86.5362 33.561 82.1652L58.3218 39.2783Z"
      fill="#DB0000"
    />
    <path
      d="M61.1948 54.2013C61.1426 52.6707 62.3692 51.4016 63.9006 51.4016H64.0997C65.6311 51.4016 66.8577 52.6707 66.8055 54.2013L66.2394 70.8061C66.2039 71.8479 65.3491 72.674 64.3067 72.674H63.6936C62.6512 72.674 61.7964 71.8479 61.7609 70.8061L61.1948 54.2013Z"
      fill="white"
    />
    <path
      d="M67.1594 79.5232C67.1594 81.2681 65.7449 82.6825 64.0001 82.6825C62.2553 82.6825 60.8408 81.2681 60.8408 79.5232C60.8408 77.7784 62.2553 76.364 64.0001 76.364C65.7449 76.364 67.1594 77.7784 67.1594 79.5232Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4181_15309"
        x1={42.9061}
        y1={-18.6413}
        x2={79.1937}
        y2={109.812}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E75300" stopOpacity={0.7} />
        <stop offset={1} stopColor="#FFBEA9" stopOpacity={0} />
      </linearGradient>
    </defs>
  </svg>
);

export default Alert;
