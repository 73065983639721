import "./style.scss";

import React, { useEffect, useState } from "react";

interface DropdownProps {
  children: React.ReactNode;
  subMenu: Array<{
    item: React.ReactNode;
    onChoose: () => void;
  }>;
}

const owlClass = "dropdown";

const Dropdown: React.FC<DropdownProps> = ({ children, subMenu }) => {
  const [visibleContent, setVisibleContent] = useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const handleTouchParentDropdown = () => {
    setVisibleContent((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setVisibleContent(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={owlClass} ref={containerRef}>
      <div onClick={handleTouchParentDropdown}>{children}</div>

      <div
        className={`${owlClass}__content`}
        style={{ display: visibleContent ? "block" : "none" }}
      >
        {subMenu.map(({ item, onChoose }, idx) => (
          <div
            key={`${item?.toString()}-${idx}`}
            className={`${owlClass}__content__item`}
            onClick={onChoose}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
