import Button from "components/button";
import Text from "components/text";
import { ModalType } from "constant/home";
import { convertTimestampToDateString } from "utils/helper";

export const getFreezeUpgradeModal = (
  okCallback = () => {},
  cancelCallback = () => {},
  cooldownBasic = 0
): ModalType => {
  return {
    title: "zBusiness Basic",
    description: (
      <div className="notify-modal">
        <Text
          center
          size="xlg-28"
          className="notify-modal__title_no_icon"
          weight="three-quarters-bold"
        >
          Chưa thể đăng ký lại gói Basic
        </Text>
        <Text center size="xlg-18">
          {`Bạn vừa hủy gói Basic và chưa thể đăng ký lại cho đến ${convertTimestampToDateString(
            cooldownBasic
          )}. Nâng cấp lên gói Pro để tận hưởng nhiều đặc quyền vượt trội.`}
        </Text>
      </div>
    ),
    okBtn: [
      <Button
        type="primary"
        size="large"
        block
        key="register-code"
        onClick={() => {
          okCallback();
        }}
      >
        Nâng cấp lên gói Pro
      </Button>,
      <Button
        size="large"
        type="ghost-primary"
        block
        key="register-code-back"
        onClick={() => {
          cancelCallback();
        }}
      >
        Đã hiểu
      </Button>,
    ],
  };
};
