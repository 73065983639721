import CheckMarkIcon from "icons/CheckMarkIcon";
import CircleIcon from "icons/CircleIcon";
import { useEffect, useState } from "react";

const RadioButton: React.FC<{ isChecked: boolean }> = ({
  isChecked = false,
}) => {
  const [isCheckedState, setCheckedState] = useState(isChecked);

  useEffect(() => {
    setCheckedState(isChecked);
  }, [isChecked]);
  return (
    <div>
      {isCheckedState ? (
        <CheckMarkIcon></CheckMarkIcon>
      ) : (
        <CircleIcon></CircleIcon>
      )}
    </div>
  );
};

export default RadioButton;
