import type { IconProps } from "./model";

const BlueAutoReplyIcon = ({}: IconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <path
      fill="#0068FF"
      fillRule="evenodd"
      stroke="#0068FF"
      strokeWidth={0.6}
      d="M15.529 14.8H7.5A1.8 1.8 0 0 1 5.7 13V6a1.8 1.8 0 0 1 1.8-1.8H19A1.8 1.8 0 0 1 20.8 6v7a1.8 1.8 0 0 1-1.8 1.8h-1.2v2.482L15.53 14.8ZM15 16H7.5a3 3 0 0 1-3-3V8.6h-.3a1 1 0 0 0-1 1v6A1.8 1.8 0 0 0 5 17.4h.832v2.185L7.98 17.4h6.09a.6.6 0 0 1 0 1.2H8.484L5.66 21.472a.6.6 0 0 1-1.028-.42v-2.474A3 3 0 0 1 2 15.6v-6a2.2 2.2 0 0 1 2.2-2.2h.3V6a3 3 0 0 1 3-3H19a3 3 0 0 1 3 3v7a3 3 0 0 1-3 3v2.827a.6.6 0 0 1-1.043.405L15 16ZM9.722 8.6l2.552-2.486a.4.4 0 0 1 .679.287V7.95h.097a3.703 3.703 0 0 1 3.703 3.703c0 .225-.297.306-.414.113a3.75 3.75 0 0 0-3.204-1.81h-.182v1.63a.4.4 0 0 1-.683.283L9.716 9.311a.5.5 0 0 1 .005-.711Z"
      clipRule="evenodd"
    />
  </svg>
);

export default BlueAutoReplyIcon;
