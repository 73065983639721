import './style.scss';

import RadioButton from 'components/radio-button/radio-button';
import { translateKeys } from 'constant/TypeData';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const owlClass = "radio-button-group";
const RadioButtonGroup: React.FC<{
  customContainerStyle: React.CSSProperties;
  data: Array<{ value: number; label: translateKeys }>;
  setMarkedItemIndex: (index: number) => void;
  markedItemIndex: number;
}> = ({
  customContainerStyle = {},
  data = [],
  setMarkedItemIndex,
  markedItemIndex = -1,
}) => {
  const { t } = useTranslation();
  const [selectedItemIndex, setSelectedItemIndex] = useState(markedItemIndex);

  const changeSelect = (index: number) => {
    setSelectedItemIndex(index);
    setMarkedItemIndex(index);
  };

  return (
    <ul className={`${owlClass}__list-item`} style={customContainerStyle}>
      {data.map((item, index) => (
        <li
          className={`${owlClass}__row`}
          key={index}
          onClick={() => changeSelect(index)}
        >
          <RadioButton isChecked={selectedItemIndex === index}></RadioButton>

          <div className={`${owlClass}__text-checkbox`}>{t(item.label)}</div>
        </li>
      ))}
    </ul>
  );
};

export default RadioButtonGroup;
