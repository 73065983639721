import "./style.scss";

import BlueButton from "components/blue-button";
import RadioButtonGroup from "components/radio-button-group/radio-button-group";
import { REASON_LIST_DATA } from "constant/data";
import { DATA_RESULT_MODAL } from "constant/data-result-modal";
import { useAppSelector } from "hooks";
import { useResultModal } from "hooks/useResultModal";
import { CloseIcon } from "icons";
import CancelBasicIcon from "icons/CancelBasicIcon";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { BottomSheet } from "react-spring-bottom-sheet";
import home from "services/home";
import { CODE } from "services/http-common";
import { RootState } from "store";
import { convertTimestampToDateString } from "utils/helper";

const owlClass = "downgrade-basic-bottom-sheet";
const DowngradeBasicBottomSheet: React.FC<{
  visible: boolean;
  setVisible: (visible: boolean) => void;
}> = ({ visible, setVisible }) => {
  const [markedItemIndex, setMarkedItemIndex] = useState(-1);
  const { setInfoResultModal } = useResultModal();
  const [otherReasonText, setOtherReasonText] = useState("");
  const { profile } = useAppSelector((state: RootState) => state.profile);
  const { t } = useTranslation();

  const disableButton =
    markedItemIndex === -1 ||
    (otherReasonText.length === 0 &&
      markedItemIndex === REASON_LIST_DATA.length - 1);

  const handleClose = () => {
    setVisible(false);
  };

  const continueBasic = () => {
    handleClose();
    if (window.ZJSBridge) {
      window.ZJSBridge.H5.changeTitleHeader(t("manage-basic"));
    }
    setInfoResultModal({
      visible: true,
      info: DATA_RESULT_MODAL.CONTINUE_BASIC,
    });

    home.trackingLog({
      flow: "Downgrade",
      action: "click_keep_basic_survey_screen",
    });
  };

  const downgradeBasic = () => {
    if (!profile) return;
    const { userId } = profile;
    home.trackingLog({
      flow: "Downgrade",
      action: "click_cancel_basic_survey_screen",
      params: JSON.stringify({
        reasonType: REASON_LIST_DATA[markedItemIndex].value,
        otherReason: otherReasonText,
      }),
    });

    home
      .downgradeBasic({ userId })
      .then((resp) => {
        handleClose();
        const isSuccess = resp?.error_code === CODE.SUCCESS;

        setInfoResultModal({
          visible: true,
          info: isSuccess
            ? {
                ...DATA_RESULT_MODAL.CANCEL_BASIC_SUCCESS,
                desc: t("disclaimer-cancel-basic-2", {
                  date: convertTimestampToDateString(
                    parseInt(resp?.data?.cooldownBasic || "0")
                  ),
                }),
              }
            : DATA_RESULT_MODAL.CANCEL_BASIC_FAIL,
        });

        home.trackingLog({
          flow: "Downgrade",
          action: isSuccess ? "cancel_basic_success" : "cancel_basic_fail",
          ...(isSuccess
            ? {}
            : { params: JSON.stringify({ error_code: resp?.error_code }) }),
        });
      })
      .catch((err: any) => {
        console.error("downgradeBasic API error:", err);
        setInfoResultModal({
          visible: true,
          info: DATA_RESULT_MODAL.CANCEL_BASIC_FAIL,
        });
      });
  };

  return (
    <BottomSheet open={visible} onDismiss={handleClose}>
      <div className={`${owlClass}__container`}>
        <div className={`${owlClass}__close-btn`}>
          <CloseIcon
            size={13.2}
            color="#0D0D0D"
            onClick={handleClose}
          ></CloseIcon>
        </div>

        <div className={`${owlClass}__title-container`}>
          <CancelBasicIcon></CancelBasicIcon>

          <h2 className={`${owlClass}__title`}>{t("title-downgrade-sheet")}</h2>

          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <RadioButtonGroup
              customContainerStyle={{ marginTop: "24px" }}
              data={REASON_LIST_DATA}
              setMarkedItemIndex={setMarkedItemIndex}
              markedItemIndex={markedItemIndex}
            />

            {markedItemIndex === REASON_LIST_DATA.length - 1 && (
              <div className={`${owlClass}__text-arena-container`}>
                <textarea
                  placeholder={t("input-reason")}
                  className={`${owlClass}__text-arena`}
                  value={otherReasonText}
                  onChange={(e) => setOtherReasonText(e.target.value.trim())}
                />
              </div>
            )}

            <div
              className={`manage-basic__footer`}
              style={{ position: "static", marginTop: "12px" }}
            >
              <BlueButton
                disabled={disableButton}
                content={t("continue-basic-plan")}
                customStyleContainer={{
                  width: "100%",
                }}
                handleTouchButton={continueBasic}
              ></BlueButton>
              <button
                disabled={disableButton}
                className={`manage-basic__footer__cancel-btn ${
                  disableButton
                    ? "manage-basic__footer__cancel-btn--disabled"
                    : ""
                }`}
                onClick={downgradeBasic}
              >
                {t("cancel-anyway")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </BottomSheet>
  );
};

export default DowngradeBasicBottomSheet;
