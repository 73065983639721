import "react-spring-bottom-sheet/dist/style.css";
import "./style.scss";

import DowngradeBasicBottomSheet from "components/downgrade-basic-bottom-sheet/downgrade-basic-bottom-sheet";
import Layout from "components/layout";
import Spin from "components/spin";
import Text from "components/text";
import { DATA_RESULT_MODAL } from "constant/data-result-modal";
import { IResultModal, TYPE_USER } from "constant/TypeData";
import { useAppSelector } from "hooks";
import { useActivePackageInfo } from "hooks/useActivePackage";
import useNotify from "hooks/useNotify";
import { useResultModal } from "hooks/useResultModal";
import i18n from "i18n";
import Phone from "images/Phone";
import ResultModal from "pages/result-modal";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import home from "services/home";
import { CODE } from "services/http-common";
import { RootState, store } from "store";
import { onFetchProfile } from "store/profile";
import {
  isPC,
  isTablet,
  isWebViewZaloMobile,
  setTrackingReferer,
} from "utils/helper";

import DowngradeBasic from "./downgrade-basic";
import UpgradeBasic from "./upgrade-basic";

const owlClass = "manage-basic";
const ZALO_JS_BRIDGE_URL = "https://h5.zdn.vn/jssdk/1.16.7/lib.js";

const ManageBasic: React.FC = () => {
  const [visibleDownGradeBottomSheet, setVisibleDownGradeBottomSheet] =
    useState(false);

  const [isJSBridgeLoaded, setIsJSBridgeLoaded] = useState(false);
  const { activePackageInfo, setActivePackageInfo } = useActivePackageInfo();

  const { infoResultModal } = useResultModal();
  const { setShowNotifyModal } = useNotify();

  const initLogin = useRef<boolean>(false);
  const firstVisitPage = useRef<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const { profile } = useAppSelector((state: RootState) => state.profile);

  //const isInWebViewZaloMobile = true;
  const isInWebViewZaloMobile = isWebViewZaloMobile();

  const isFlowUpgrade = location.pathname.includes("upgrade-basic");

  if (activePackageInfo && firstVisitPage.current) {
    firstVisitPage.current = false;
    home.trackingLog({
      flow: isFlowUpgrade ? "Upgrade" : "Downgrade",
      action: "visit_paywall_basic",
      params: JSON.stringify({
        userType: activePackageInfo.typeUser,
        freeze_reactivate_time: null,
      }),
    });
  }

  const doLogin = () => {
    if (!initLogin.current) {
      setTrackingReferer();
      store.dispatch(
        onFetchProfile(
          `${import.meta.env.VITE_DOMAIN}/${
            isFlowUpgrade ? "upgrade" : "downgrade"
          }-basic`,
          {
            flow: `${location.pathname}`,
            action: "sign_in_success",
          },
          true
        )
      );
      initLogin.current = true;
    }
  };

  useEffect(() => {
    console.log("userAgen:", window.navigator.userAgent);
    console.log("isPC", isPC());
    console.log("isTablet", isTablet());
    if (isPC() || isTablet() || !isInWebViewZaloMobile) {
      navigate("/");
      setShowNotifyModal({
        description: (
          <div className="notify-modal">
            <Phone></Phone>
            <Text
              center
              size="xlg-24"
              className="notify-modal__title"
              weight="three-quarters-bold"
            >
              {t("please-use-phone-for-using")}
            </Text>
            <Text center size="xlg-18">
              {t("please-open-zalo-app")}
            </Text>
          </div>
        ),
      });

      return;
    }

    doLogin();
    const script = document.createElement("script");
    script.src = ZALO_JS_BRIDGE_URL;
    script.async = true;
    script.onload = () => setIsJSBridgeLoaded(true);
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (isInWebViewZaloMobile && window.ZJSBridge && isJSBridgeLoaded) {
      window.ZJSBridge.H5.configRightMenu([]);
      i18n.changeLanguage(window.ZJSBridge.Device.getLanguage());

      const dataConfig = {
        textColor: "white",
        confirmToExit: 2,
        ...(location.pathname !== "/manage-basic" && {
          backgroundColor: "#0068FF",
        }),
      };

      window.ZJSBridge.callCustomAction("action.change.actionbar", {
        actionbarType: 0,
        buttonType: 1,
        dataConfig: dataConfig,
      });
      window.ZJSBridge.H5.configLeftButton({
        buttonType: 1,
        dataConfig: {
          confirmToExit: 2,
        },
      });
      window.ZJSBridge.H5.changeTitleHeader(
        isFlowUpgrade ? t("upgrade-account") : t("cancel-basic")
      );

      window.ZJSBridge.onCloseWebview(() => {
        home
          .trackingLog({
            flow: isFlowUpgrade ? "Upgrade" : "Downgrade",
            action: "dismiss",
          })
          .finally(() => {
            window.ZJSBridge.H5.closeWebview();
          });
      });

      window.history.pushState(null, "", window.location.href);
      window.addEventListener("popstate", function () {
        window.ZJSBridge.H5.closeWebview();
      });
    }
  }, [isJSBridgeLoaded]);

  useEffect(() => {
    if (!profile) return;
    const { userId } = profile;
    home.getActivePackage({ userId }).then((resp) => {
      if (resp?.error_code === CODE.SUCCESS) {
        setActivePackageInfo(resp.data || null);
      }
    });
  }, [profile]);

  const HeaderBg = (
    <img
      className={`${owlClass}__bg-header`}
      src="https://res-zalo.zadn.vn/upload/media/2024/6/6/Header_1717652152596_796302.svg"
      alt="header"
    />
  );

  // const renderResultModal = (data: IResultModal) => <ResultModal data={data} />;

  const { visible: modalIsVisible } = infoResultModal;

  const UpgradeFlow = (
    <div className={owlClass} style={{ display: modalIsVisible ? "none" : "" }}>
      <UpgradeBasic />
    </div>
  );

  const DowngradeFlow = (
    <div className={owlClass} style={{ display: modalIsVisible ? "none" : "" }}>
      <DowngradeBasic
        setVisibleDownGradeBottomSheet={setVisibleDownGradeBottomSheet}
      />
      <DowngradeBasicBottomSheet
        visible={visibleDownGradeBottomSheet}
        setVisible={setVisibleDownGradeBottomSheet}
      />
    </div>
  );

  const FlowLayout = ({
    children,
    modalData,
  }: {
    children?: React.ReactNode;
    modalData: IResultModal;
  }) => {
    return (
      <>
        {HeaderBg}
        {children}
        <ResultModal data={modalData} />
      </>
    );
  };

  const FullLayout = ({ children }: { children: React.ReactNode }) => {
    return (
      <Layout>
        {HeaderBg}
        {children}
      </Layout>
    );
  };

  const showUIByFlow = () => {
    if (!activePackageInfo) return <></>;
    const { typeUser = TYPE_USER.NORMAL } = activePackageInfo;
    if (isFlowUpgrade) {
      let modalData;

      if (typeUser === TYPE_USER.BASIC) {
        modalData = {
          info: DATA_RESULT_MODAL.ALREADY_BASIC_FOR_UPGRADE,
          visible: true,
        };
      } else if (typeUser === TYPE_USER.PRO) {
        modalData = {
          info: DATA_RESULT_MODAL.ALREADY_PRO_FOR_UPGRADE,
          visible: true,
        };
      } else {
        modalData = infoResultModal;
      }

      return (
        <FlowLayout modalData={modalData}>
          {typeUser !== TYPE_USER.BASIC &&
            typeUser !== TYPE_USER.PRO &&
            UpgradeFlow}
        </FlowLayout>
      );
    }

    //FLOW DOWNGRADE
    if (typeUser === TYPE_USER.BASIC) {
      return (
        <FlowLayout modalData={infoResultModal}>{DowngradeFlow}</FlowLayout>
      );
    }

    return (
      <FlowLayout
        modalData={{
          info: DATA_RESULT_MODAL.NOT_BASIC_USER_FOR_DOWNGRADE,
          visible: true,
        }}
      />
    );
  };

  if (activePackageInfo?.typeUser) {
    return showUIByFlow();
  }

  return (
    <FullLayout>
      <div className={`${owlClass}__loading`}>
        <Spin></Spin>
      </div>
    </FullLayout>
  );
};

export default ManageBasic;
