import './style.scss';

import BlueButton from 'components/blue-button';
import { t } from 'i18next';
import { isWebViewZaloMobile } from 'utils/helper';

const owlClass = "result-modal";
const ResultModal: React.FC<{
  data: {
    info: any;
    handleTouchButton?: () => void | undefined;
    visible: boolean;
  };
}> = ({ data }) => {
  const { info = {}, handleTouchButton, visible = false } = data;
  const { img, title, desc } = info;

  const handleClickButton = () => {
    if (handleTouchButton) {
      handleTouchButton();
      return;
    }

    console.log("close website");
    if (isWebViewZaloMobile()) {
      window.ZJSBridge && window.ZJSBridge.H5.closeWebview();
    } else {
      window.close();
    }
  };

  return (
    <div
      className={`${owlClass}`}
      style={{ display: visible ? "flex" : "none" }}
    >
      <div className={`${owlClass}__container`}>
        <div className={`${owlClass}__img`}>{img}</div>
        <h1 className={`${owlClass}__title`}>{t(title)}</h1>
        <p
          className={`${owlClass}__desc`}
          dangerouslySetInnerHTML={{ __html: t(desc) }}
        />
        {/* <div
        dangerouslySetInnerHTML={{ __html: "<strong>strong text</strong>" }}
      /> */}
        <div className={`${owlClass}__button-container`}>
          <BlueButton
            content={t("understand")}
            handleTouchButton={handleClickButton}
            customStyleContainer={{ minWidth: "232px" }}
          ></BlueButton>
        </div>
      </div>
    </div>
  );
};

export default ResultModal;
