import { PC_AGENT } from "constant/home";
import { TYPE_USER } from "constant/TypeData";
import home from "services/home";

import {
  getValueFromSessionStorage,
  setValueToSessionStorage,
} from "./local-storage";

export const isWebViewZaloMobile = () => {
  if (typeof window !== "undefined") {
    const userAgent = navigator.userAgent;
    return !isWebViewZaloPC() && userAgent.toLowerCase().includes("zalo");
  }
  return false;
};

export const isWebViewZaloPC = () => {
  if (typeof window !== "undefined") {
    const userAgent = navigator.userAgent;
    return userAgent.toLowerCase().includes(PC_AGENT.toLowerCase());
  }
  return false;
};

export const isWebViewZalo = () => {
  return isWebViewZaloMobile() || isWebViewZaloPC();
};

export const isPC = () => {
  return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};

export const isTablet = () => {
  const userAgent = navigator.userAgent.toLowerCase();

  // Phát hiện các thiết bị gập như Galaxy Z Fold
  const isFoldable =
    /sm-f[0-9]{3}[a-z]/i.test(userAgent) && userAgent.includes("android");

  // Phát hiện các thiết bị tablet phổ biến
  const isTabletDevice =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone).*touch)|kindle|playbook|silk|puffin(?!.*(ip|ap|wp)))/i.test(
      userAgent
    ) && !/mobile/i.test(userAgent);

  // Kiểm tra kích thước màn hình để nhận diện thêm (dành cho các thiết bị không rõ trong userAgent)
  const isTabletScreen = window.innerWidth >= 600 && window.innerWidth <= 1024;

  // Trả về true nếu thiết bị là tablet (dựa trên userAgent hoặc kích thước) và không phải là thiết bị gập
  return (isTabletDevice || isTabletScreen) && !isFoldable;
};

// export const isMobile = () => {
//   return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//     navigator.userAgent
//   );
// };

export const getDateAfterOneMonth = (timestamp: number) => {
  const date = new Date(timestamp);
  const currentDay = date.getDate();

  date.setMonth(date.getMonth() + 1);

  // Nếu ngày sau khi cộng không khớp với ngày ban đầu, tức là tháng sau không có ngày đó
  if (date.getDate() < currentDay) {
    date.setDate(0); // Lùi về ngày cuối của tháng trước (tháng mới thiếu ngày)
  }

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const convertTimestampToDateString = (timestamp: number) => {
  const date = new Date(timestamp);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const setTrackingReferer = () => {
  const data = getValueFromSessionStorage("referer");
  if (data == null) {
    setValueToSessionStorage(
      "referer",
      JSON.stringify({
        referer: document.referrer,
        params: home.arrayToObject(new URLSearchParams(window.location.search)),
      })
    );
  }
};

export const getTypeUserFromLevel = (level: number) => {
  if (level > 30) return TYPE_USER.PRO;
  if (level === 10) return TYPE_USER.BASIC;
  return TYPE_USER.NORMAL;
};
