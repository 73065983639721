const BlueGroupIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={18} fill="none">
    <path
      fill="#0068FF"
      fillRule="evenodd"
      stroke="#0068FF"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={0.6}
      d="M12 .9c-1.176 0-2.045.506-2.6 1.28-.535.75-.75 1.707-.75 2.614 0 1.324.315 2.397.916 3.153C10.18 8.717 11.044 9.1 12 9.1c.957 0 1.822-.383 2.434-1.153.601-.756.916-1.83.916-3.153 0-.907-.214-1.865-.75-2.614C14.046 1.406 13.177.9 12 .9ZM9.85 4.794c0-.74.178-1.43.527-1.915.33-.461.835-.779 1.623-.779s1.294.318 1.624.779c.348.486.526 1.175.526 1.915 0 1.147-.274 1.927-.655 2.406-.37.465-.88.7-1.495.7-.615 0-1.125-.235-1.494-.7-.382-.48-.656-1.259-.656-2.406ZM19 5.4c-.822 0-1.44.36-1.833.915-.374.528-.517 1.191-.517 1.803 0 .88.208 1.622.629 2.157.433.55 1.048.825 1.721.825a3.25 3.25 0 0 1 3.396 2.736l.006.033.008.031h-3.684c-.88-2.22-3.386-4-6.744-4-3.358 0-5.864 1.78-6.744 4H1.56l.009-.03.005-.034A3.251 3.251 0 0 1 4.98 11.1H5c.673 0 1.289-.274 1.722-.825.42-.535.628-1.276.628-2.157 0-.612-.142-1.275-.516-1.803C6.44 5.761 5.822 5.4 5 5.4c-.822 0-1.44.36-1.833.915-.374.528-.517 1.191-.517 1.803 0 .831.185 1.539.56 2.066A4.45 4.45 0 0 0 .395 13.62a1.203 1.203 0 0 0 1.007 1.475l.04.005H4.93c-.011.092-.02.184-.025.277A1.611 1.611 0 0 0 6.5 17.1h10.962a1.61 1.61 0 0 0 1.598-1.723 4.572 4.572 0 0 0-.026-.277h3.494l.04-.005a1.2 1.2 0 0 0 1.008-1.475 4.45 4.45 0 0 0-2.793-3.428c.38-.527.566-1.238.566-2.074 0-.612-.142-1.275-.516-1.803C20.44 5.761 19.822 5.4 19 5.4ZM5 9.9h-.016l-.062-.002a.927.927 0 0 1-.7-.365c-.204-.259-.372-.708-.372-1.415 0-.448.108-.843.296-1.11.17-.24.426-.408.854-.408s.684.169.854.409c.189.266.296.661.296 1.109 0 .707-.167 1.156-.371 1.415A.939.939 0 0 1 5 9.9Zm14.095-.004a.924.924 0 0 0 .684-.363c.204-.259.371-.708.371-1.415 0-.448-.107-.843-.296-1.11-.17-.24-.425-.408-.854-.408-.428 0-.684.169-.854.409-.188.266-.296.661-.296 1.109 0 .707.168 1.156.372 1.415.19.241.444.364.765.367l.108-.004ZM6.101 15.453c.128-2.076 2.283-4.353 5.881-4.353 3.598 0 5.753 2.277 5.881 4.353v.006a.411.411 0 0 1-.406.441H6.507a.41.41 0 0 1-.406-.441v-.006Z"
      clipRule="evenodd"
    />
  </svg>
);

export default BlueGroupIcon;
