import "./App.scss";

import Loading from "components/loading/loading";
import { UpgradeProModal } from "components/modals/upgrade-pro-modal";
import NotifyProvider from "context/Notify";
import CodePage from "pages/code/code";
import Home from "pages/home";
import Main from "pages/main";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";

import routes from "./routes";
import { store } from "./store";

function App() {
  return (
    <Provider store={store}>
      <RecoilRoot>
        <NotifyProvider>
          <Router>
            <Routes>
              <Route element={<Main />}>
                <Route key="home" path="/" element={<Home />} />
                <Route key="mua-code" path="/mua-code" element={<CodePage />} />
              </Route>
              {routes.map((route) => (
                <Route {...route} key={route.key} />
              ))}
            </Routes>
          </Router>
          <UpgradeProModal></UpgradeProModal>
        </NotifyProvider>
        <Loading></Loading>
      </RecoilRoot>
    </Provider>
  );
}

export default App;
