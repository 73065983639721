const CircleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
    <rect width={22} height={22} x={1} y={1} fill="#fff" rx={11} />
    <rect
      width={22}
      height={22}
      x={1}
      y={1}
      stroke="#C2C7CB"
      strokeWidth={2}
      rx={11}
    />
  </svg>
);

export default CircleIcon;
