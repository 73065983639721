import "whatwg-fetch";
import "abortcontroller-polyfill/dist/abortcontroller-polyfill-only";

import { REQUEST_TIMEOUT } from "constant/request-errors";

import type { GetParams, ReturnedData } from "./model";
const toQueryString = (object: Record<string, any>) =>
  new URLSearchParams(object).toString();

const getApi = async <T = any>({
  domain = import.meta.env.VITE_API,
  path = "",
  success,
  error,
  query,
  manualQuery = "",
  timeout = REQUEST_TIMEOUT,
}: GetParams<T>): Promise<ReturnedData<T>> => {
  let controller;
  try {
    controller = new AbortController();
  } catch (err) {
    controller = null;
  }
  const idTimeout = setTimeout(() => controller?.abort(), timeout);

  try {
    const resp = await fetch(
      `${domain}${path}${
        query ? `?${toQueryString(query)}` : ""
      }${manualQuery}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        signal: controller?.signal,
      }
    );
    clearTimeout(idTimeout);
    if (!resp.ok) {
      throw new TypeError(
        `Fetched error status: ${resp.status} ${resp.statusText}`
      );
    }
    const data = await resp.json();
    success?.(data || {});
    return data || {};
  } catch (ex) {
    error?.(ex);
    return {};
  }
};

export default getApi;
