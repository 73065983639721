import './style.scss';

import BlueButton from 'components/blue-button';
import RadioButtonGroup from 'components/radio-button-group/radio-button-group';
import { REASON_LIST_DATA } from 'constant/data';
import { DATA_RESULT_MODAL } from 'constant/data-result-modal';
import { useResultModal } from 'hooks/useResultModal';
import React, { useState } from 'react';
import home from 'services/home';

const owlClass = "downgrade-basic-form-popup";
const DowngradeBasicFormPopup: React.FC<{
  setVisible: (visible: boolean) => void;
}> = ({ setVisible }) => {
  const [markedItemIndex, setMarkedItemIndex] = useState(-1);
  const { setInfoResultModal } = useResultModal();
  const [otherReasonText, setOtherReasonText] = useState("");

  console.log("markedItemIndex", markedItemIndex);

  const handleClose = () => {
    setVisible(false);
  };

  const continueBasic = () => {
    handleClose();
    setInfoResultModal({
      visible: true,
      info: DATA_RESULT_MODAL.CONTINUE_BASIC,
    });

    home.trackingLog({
      flow: "Downgrade",
      action: "click_keep_basic_survey_screen",
    });
  };

  const downgradeBasic = () => {
    home.trackingLog({
      flow: "Downgrade",
      action: "click_cancel_basic_survey_screen",
      params: JSON.stringify({
        reasonType: REASON_LIST_DATA[markedItemIndex].value,
        otherReason: otherReasonText,
      }),
    });
  };

  return (
    <div className={`${owlClass}__popup`}>
      {/* <div className={`${owlClass}__close-btn`}>
        <CloseIcon
          size={13.2}
          color="#0D0D0D"
          onClick={handleClose}
        ></CloseIcon>
      </div> */}

      <div className={`${owlClass}__container`}>
        <h2 className={`${owlClass}__title`}>
          Bạn chắc chắn muốn huỷ<br></br>gói Basic?
        </h2>

        <div>
          <p className={`${owlClass}__sub-title`}>
            Chia sẻ lý do với zBusiness
          </p>
          <RadioButtonGroup
            customContainerStyle={{ marginTop: "16px" }}
            data={REASON_LIST_DATA}
            setMarkedItemIndex={setMarkedItemIndex}
            markedItemIndex={markedItemIndex}
          />

          {markedItemIndex === REASON_LIST_DATA.length - 1 && (
            <div className={`${owlClass}__text-arena-container`}>
              <textarea
                placeholder="Nhập lý do..."
                className={`${owlClass}__text-arena`}
                value={otherReasonText}
                onChange={(e) => setOtherReasonText(e.target.value.trim())}
              />
            </div>
          )}
        </div>

        <div className={`manage-basic__footer`} style={{ marginTop: "0px" }}>
          <BlueButton
            disabled={markedItemIndex === -1}
            content="Tiếp tục sử dụng gói Basic"
            customStyleContainer={{
              width: "100%",
            }}
            handleTouchButton={continueBasic}
          ></BlueButton>
          <button
            className={`manage-basic__footer__cancel-btn`}
            onClick={downgradeBasic}
          >
            Hủy gói
          </button>
        </div>
      </div>
    </div>
  );
};

export default DowngradeBasicFormPopup;
