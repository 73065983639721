import './style.scss';

const BlueButton: React.FC<{
  content: string;
  customStyleContainer?: React.CSSProperties;
  disabled?: boolean;
  handleTouchButton?: () => void | undefined;
}> = ({
  content = "",
  customStyleContainer = {},
  disabled = false,
  handleTouchButton = () => {},
}) => {
  return (
    <button
      className={`blue-button ${disabled ? "blue-button--disabled" : ""}`}
      style={customStyleContainer}
      onClick={() => {
        if (disabled) return;
        handleTouchButton();
      }}
    >
      {content}
    </button>
  );
};
export default BlueButton;
