import { useSetRecoilState } from "recoil";

import { loadingState } from "../states/loadingState";

export const useLoading = () => {
  const setLoading = useSetRecoilState(loadingState);

  let timeoutId: NodeJS.Timeout;

  const showLoading = () => {
    setLoading(true);

    timeoutId = setTimeout(() => {
      setLoading(false);
    }, 20000);
  };

  const hideLoading = () => {
    clearTimeout(timeoutId);
    setLoading(false);
  };

  return { showLoading, hideLoading };
};
