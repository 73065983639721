import resources from "locales";
import React from "react";

export enum TYPE_USER {
  NORMAL = "NORMAL",
  BASIC = "BASIC",
  PRO = "PRO",
}

export enum DATA_RESULT_MODAL_KEY {
  UPGRADE_BASIC_SUCCESS = "UPGRADE_BASIC_SUCCESS",
  UPGRADE_BASIC_FAIL = "UPGRADE_BASIC_FAIL",
  NOT_YET_EKYC_UPGRADE_BASIC = "NOT_YET_EKYC_UPGRADE_BASIC",
  ALREADY_YET_EKYC_UPGRADE_BASIC = "ALREADY_YET_EKYC_UPGRADE_BASIC",
  NOT_BASIC_USER_FOR_DOWNGRADE = "NOT_BASIC_USER_FOR_DOWNGRADE",
  NOT_OPEN_BY_PHONE = "NOT_OPEN_BY_PHONE",
  CANCEL_BASIC_SUCCESS = "CANCEL_BASIC_SUCCESS",
  CONTINUE_BASIC = "CONTINUE_BASIC",
}

export interface IResultModal {
  info: IResultModalInfo;
  handleTouchButton?: () => void | undefined;
  visible: boolean;
}

export interface IResultModalInfo {
  img: React.ReactNode;
  title: string;
  desc: string;
}

export interface IUpgradeProModalState {
  data: {
    source?: string;
    flow?: string;
  };
  visible: boolean;
}

export type translateKeys = keyof (typeof resources)["vi"]["translation"];

export interface IActivePackageState {
  typeUser: TYPE_USER;
  cooldownBasic: string;
}
