import en from "./en.json";
import vi from "./vi.json";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  vi: {
    translation: vi,
  },
  en: {
    translation: en,
  },
} as const;

export default resources;
