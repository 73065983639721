import './style.scss';

import RegisterPackage from 'components/register-package';
import Text from 'components/text';
import { IS_TRIAL, Z_BUSINESS_BENEFIT } from 'constant/home';
import useWindowSize from 'hooks/use-window-size';
import { CheckIcon } from 'icons';
import React, { useMemo } from 'react';
import { PackageDispI } from 'z-types/home';

interface PricePackagesLiteProps {
  productSelected: string;
  source: string;
  products: PackageDispI[];
}

const owlClass = "price-packages-lite";

const PricePackagesLite: React.FC<PricePackagesLiteProps> = ({
  productSelected,
  source,
  products,
}: PricePackagesLiteProps) => {
  const { width } = useWindowSize();
  const isDesktop = useMemo(() => width > 1150, [width]);

  return (
    <div className={owlClass}>
      <div className={`${owlClass}__container`}>
        {products
          .filter(
            (pkg) =>
              !pkg.hidden &&
              (pkg.packageId === productSelected || !pkg.isRecommend)
          )
          .map(
            ({
              isRecommend,
              packageId,
              label,
              name,
              caption,
              ctaBtn,
              benefit,
              priceLabel,
              extraLabel,
              productId,
            }) => (
              <div
                className={
                  isRecommend
                    ? `${owlClass}__container__package ${owlClass}__container__package--activate`
                    : `${owlClass}__container__package`
                }
                key={label}
              >
                <div className={`${owlClass}__container__package__top`}>
                  <Text
                    size={isDesktop ? "xlg-36" : "xlg-28"}
                    weight="three-quarters-bold"
                  >
                    {label}
                  </Text>
                  <Text
                    size="md-14"
                    color="secondary"
                    className={`${owlClass}__container__package__top__pay-type`}
                  >
                    {caption}
                  </Text>
                  <Text
                    size={isDesktop ? "xlg-28" : "xlg-22"}
                    weight="bold"
                    className={`${owlClass}__container__package__top__price-type`}
                  >
                    {packageId === IS_TRIAL
                      ? priceLabel
                      : `${priceLabel}/ tháng`}
                    {extraLabel && (
                      <div
                        className={`${owlClass}__container__package__top__extra-labels`}
                      >
                        {extraLabel}
                      </div>
                    )}
                  </Text>
                  <div>
                    <RegisterPackage
                      isTrial={packageId === IS_TRIAL}
                      isOpenTrialModalLoggedIn={false}
                      name={name}
                      product={{ productId: productId }}
                      btnText={ctaBtn}
                      isBlock
                      size={isDesktop ? "large" : "medium"}
                      source={source}
                      flow="Onboard Paywall"
                    />
                  </div>
                </div>
                <div className={`${owlClass}__container__package__bottom`}>
                  {Z_BUSINESS_BENEFIT[benefit].includes.map((include, idx) => (
                    <div
                      key={idx}
                      className={`${owlClass}__container__package__bottom__item`}
                    >
                      <CheckIcon
                        color="#00000099"
                        style={{ flexShrink: 0 }}
                        size={isDesktop ? 19 : 15}
                      />
                      <span>{include}</span>
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
      </div>
    </div>
  );
};

export default PricePackagesLite;
