import { translateKeys } from './TypeData';

export const REASON_LIST_DATA: { value: number; label: translateKeys }[] = [
  { value: 0, label: "have-problem-use-basic-reason" },
  {
    value: 1,
    label: "not-use-basic-reason",
  },
  { value: 2, label: "register-zstyle-reason" },
  { value: 3, label: "other-reason" },
];
