import './style.scss';

import Button from 'components/button';
import Image from 'components/image';
import Label from 'components/label';
import RegisterPackage from 'components/register-package';
import Text from 'components/text';
import { Z_BUSINESS_BENEFIT, Z_BUSINESS_LITE, Z_BUSINESS_PRO_TRIAL } from 'constant/home';
import { useAppSelector } from 'hooks';
import useWindowSize from 'hooks/use-window-size';
import useNotify from 'hooks/useNotify';
import { CheckIcon, ChevronRight, DownArrow } from 'icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import home from 'services/home';
import { RootState } from 'store';
import cx from 'utils/cx';
import { PackageDispI } from 'z-types/home';

interface PackagesBenefitProps {
  source: string;
  trialPro?: boolean;
}

const owlClass = "packages-benefit";

const PackagesBenefit: React.FC<PackagesBenefitProps> = ({
  source,
  trialPro,
}: PackagesBenefitProps) => {
  const { width } = useWindowSize();
  const isDesktop = useMemo(() => width > 1150, [width]);

  const [productSelected, setProductSelected] = useState<PackageDispI>(
    Z_BUSINESS_LITE.filter((pkg) => pkg.isRecommend)[1]
  );
  const [basicPackage, setBasicPackage] = useState<PackageDispI | null>(null);
  const { showCustomModal, setShowCustomModal } = useNotify();
  const [toggle, setToggle] = useState<boolean>(false);

  const onProductSelected = useCallback(
    (pkg: PackageDispI) => {
      setProductSelected(pkg);
    },
    [productSelected]
  );

  const onClickMoreInfo = () => {
    setToggle(!toggle);
    home.trackingLog({
      flow: "Onboard Paywall",
      action: "click_more_info",
      params: JSON.stringify({ source }),
    });
  };

  const { subscription } = useAppSelector(
    (state: RootState) => state.subscription
  );

  const findPackageActive = useCallback(() => {
    if (!subscription) return null;
    if (subscription?.basic.state >= subscription.basic.products.length) {
      return "basic";
    }
    if (subscription?.pro.state >= subscription.pro.products.length) {
      return "pro";
    }
    if (subscription?.trial.state >= subscription.trial.products.length) {
      return "trial";
    }
    return null;
  }, [subscription]);

  let renderRegisterBasic = null;

  const onRegisterTrial = () => {
    setBasicPackage(Z_BUSINESS_LITE[0]);
    home.trackingLog({
      flow: "Onboard Paywall",
      action: "click_register_trial",
      params: JSON.stringify({ source }),
    });
  };

  useEffect(() => {
    if (basicPackage) {
      setShowCustomModal({
        title: "",
        description: (
          <div
            className={`${owlClass}__product-container__package`}
            key={basicPackage.name}
          >
            <div className={`${owlClass}__product-container__package__top`}>
              <Text
                size={isDesktop ? "xlg-36" : "xlg-28"}
                weight="three-quarters-bold"
              >
                {basicPackage.name}
              </Text>
              <Text
                size="md"
                color="secondary"
                className={`${owlClass}__product-container__package__top__pay-type`}
              >
                {basicPackage.caption}
              </Text>
              <Text
                size={isDesktop ? "xlg-28" : "xlg-22"}
                weight="bold"
                className={`${owlClass}__product-container__package__top__price-type`}
                style={{ marginBottom: 16 }}
              >
                {basicPackage.priceLabel}
                {basicPackage.extraLabel && (
                  <div
                    className={`${owlClass}__product-container__package__top__extra-labels`}
                  >
                    {basicPackage.extraLabel}
                  </div>
                )}
              </Text>
              <div>
                <RegisterPackage
                  isTrial
                  isOpenTrialModalLoggedIn={false}
                  name={basicPackage.name}
                  product={{ productId: basicPackage.productId }}
                  btnText={basicPackage.ctaBtn}
                  isBlock
                  size={isDesktop ? "large" : "medium"}
                  source={source}
                  flow="Onboard Paywall"
                />
              </div>
            </div>
            <div className={`${owlClass}__product-container__package__bottom`}>
              {Z_BUSINESS_BENEFIT[basicPackage.benefit].extraIncludes.map(
                (extra) =>
                  extra.includes.map((include, idx) => (
                    <div
                      key={idx}
                      className={`${owlClass}__product-container__package__bottom__item`}
                    >
                      <CheckIcon color="#3576EB" style={{ flexShrink: 0 }} />
                      {include}
                    </div>
                  ))
              )}
            </div>
          </div>
        ),
        okBtn: <></>,
      });
    }
  }, [basicPackage, width]);

  useEffect(() => {
    if (!showCustomModal) {
      setBasicPackage(null);
    }
  }, [showCustomModal]);

  const activePackage = findPackageActive();
  if (activePackage === "basic" || activePackage == null) {
    renderRegisterBasic = (
      <>
        <div className={`${owlClass}__divider`}>Hoặc</div>
        <Button size="medium" type="ghost" onClick={onRegisterTrial}>
          <div className={`${owlClass}__register-trial`}>
            <span>Đăng ký gói Basic miễn phí</span>
            <ChevronRight />
          </div>
        </Button>
      </>
    );
  }

  const visibleProduct = Z_BUSINESS_LITE.filter((pkg) => !pkg.hidden);

  useEffect(() => {
    if (subscription) {
      console.log("visibleProduct", visibleProduct);
      setProductSelected(
        visibleProduct.find((pkg) => pkg.packageId === "pro-12-month") ||
          visibleProduct[0]
      );
    }
  }, [subscription]);

  return (
    <div className={owlClass}>
      <div className={`${owlClass}__container ${owlClass}__buy-subscription`}>
        {trialPro ? (
          <>
            <div className={`${owlClass}__trial_bg`}>
              <div className={`${owlClass}__trial-box`}>
                <div className={`${owlClass}__trial-box__content`}>
                  <div
                    className={`${owlClass}__trial-box__content__description`}
                  >
                    <Text weight="semi-bold" size="md-14">
                      {Z_BUSINESS_PRO_TRIAL.label}
                    </Text>
                    <div>
                      <Text size="xlg-36" weight="bold" color="primary">
                        {Z_BUSINESS_PRO_TRIAL.priceLabel}
                      </Text>
                      <Text size="xlg-20" weight="bold" color="primary">
                        {" "}
                        /7 ngày đầu
                      </Text>
                    </div>
                    <div className={`${owlClass}__trial-box__raw-price`}>
                      <Text lineThrough weight="semi-bold" size="md-14">
                        {Z_BUSINESS_PRO_TRIAL.rawPriceLabel}
                      </Text>
                      <span className={`${owlClass}__trial-box__badge`}>
                        {"Giá gốc"}
                      </span>
                    </div>
                  </div>
                  <Text color="secondary" size="md-14">
                    {Z_BUSINESS_PRO_TRIAL.caption}
                  </Text>
                </div>
                <RegisterPackage
                  btnText={Z_BUSINESS_PRO_TRIAL.ctaBtn}
                  name={Z_BUSINESS_PRO_TRIAL.name}
                  isBlock
                  size="medium"
                  product={{ productId: Z_BUSINESS_PRO_TRIAL.productId }}
                  source={source}
                  flow="Onboard Paywall"
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Text size="xlg-20" weight="semi-bold">
              Chọn gói đăng ký
            </Text>
            <div className={`${owlClass}__selection`}>
              {visibleProduct
                .filter((pkg) => pkg.isRecommend)
                .sort((a, b) => a.selBtn.index - b.selBtn.index)
                .map((product) => (
                  <div
                    key={product.productId}
                    className={
                      productSelected?.productId === product.productId
                        ? `${owlClass}__selection__option ${owlClass}__selection__option--activate`
                        : `${owlClass}__selection__option`
                    }
                    onClick={() => onProductSelected(product)}
                  >
                    <div className={`${owlClass}__selection__option__left`}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        <Image
                          srcImage={
                            productSelected?.productId === product.productId
                              ? "https://res-zalo.zadn.vn/upload/media/2023/10/26/icon_blue_radio_1698310463932_565835.svg"
                              : "https://res-zalo.zadn.vn/upload/media/2023/10/26/icon_radio_1698310532882_576586.svg"
                          }
                        />

                        <Text
                          color="wood-smoke"
                          weight="three-quarters-bold"
                          size="md-14"
                        >
                          {`Gói ${product.label}`}
                        </Text>
                      </div>
                    </div>
                    <div className={`${owlClass}__selection__option__right`}>
                      <div
                        className={`${owlClass}__selection__option__right__price`}
                      >
                        <Text size="xlg-18" weight="three-quarters-bold">
                          {product.packageId === "pro-12-month"
                            ? `${product.priceLabel}/ tháng`
                            : product.priceLabel}
                        </Text>
                        {product.selBtn?.badge && (
                          <Label
                            className={`${owlClass}__selection__option__discount`}
                            text={`Giảm ${product.selBtn?.badge}`}
                            type="warm"
                            style={{
                              whiteSpace: "nowrap",
                              fontSize: "10px",
                              lineHeight: "12px",
                            }}
                          />
                        )}
                        {product.extraLabel && (
                          <div
                            className={`${owlClass}__selection__option__right__extra`}
                          >
                            <Text
                              color="secondary-text"
                              size="md-14"
                              weight="medium"
                            >
                              {product.extraLabel}
                            </Text>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <RegisterPackage
              btnText={productSelected.ctaBtn}
              name={productSelected.name}
              isBlock
              size="medium"
              product={{ productId: productSelected.productId }}
              source={source}
              flow="Onboard Paywall"
            />
            {renderRegisterBasic}
          </>
        )}
      </div>
      <div
        className={cx(
          `${owlClass}__container ${owlClass}__container-benefit`,
          toggle && `${owlClass}__container-benefit__open`
        )}
      >
        <Text size="xlg-20" weight="semi-bold">
          Đặc quyền nổi bật của zBusiness Pro
        </Text>
        <div className={`${owlClass}__benefit`}>
          {Z_BUSINESS_BENEFIT.pro.includes.map((include, idx) => (
            <div key={idx} className={`${owlClass}__benefit__item`}>
              <CheckIcon color="#3576EB" style={{ flexShrink: 0 }} />
              {include}
            </div>
          ))}
        </div>
        <div
          className={cx(
            `${owlClass}__extra_benefit`,
            toggle && `${owlClass}__open`
          )}
        >
          {Z_BUSINESS_BENEFIT.pro.extraIncludes.map((extra, idx) => (
            <div className={`${owlClass}__container`} key={idx}>
              <Text size="md" weight="semi-bold">
                {extra.title}
              </Text>
              {extra.includes.map((include, index) => (
                <div
                  className={`${owlClass}__benefit`}
                  key={`include_${idx}_${index}`}
                >
                  <div className={`${owlClass}__benefit__item`}>
                    <CheckIcon color="#3576EB" style={{ flexShrink: 0 }} />
                    {include}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className={`${owlClass}__toggle`} onClick={onClickMoreInfo}>
          <span>Xem tất cả các tính năng</span>
          <DownArrow className={cx(toggle && `${owlClass}__expand`)} />
        </div>
      </div>
    </div>
  );
};

export default PackagesBenefit;
