import i18n from "i18next";
import resources from "locales";
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next

  .init({
    resources,
    fallbackLng: "vi",
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

// For IDE autocompletion
declare module "i18next" {
  interface CustomTypeOptions {
    resources: (typeof resources)["vi"];
  }
}
