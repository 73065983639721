const InfoCircleIcon = () => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.0002 2.20007C4.79694 2.20007 2.2002 4.79682 2.2002 8.00007C2.2002 11.2033 4.79694 13.8001 8.0002 13.8001C11.2034 13.8001 13.8002 11.2033 13.8002 8.00007C13.8002 4.79682 11.2034 2.20007 8.0002 2.20007ZM1.2002 8.00007C1.2002 4.24454 4.24466 1.20007 8.0002 1.20007C11.7557 1.20007 14.8002 4.24454 14.8002 8.00007C14.8002 11.7556 11.7557 14.8001 8.0002 14.8001C4.24466 14.8001 1.2002 11.7556 1.2002 8.00007ZM8.0002 6.90015C8.44202 6.90015 8.8002 7.25832 8.8002 7.70015V11.3001C8.8002 11.742 8.44202 12.1001 8.0002 12.1001C7.55837 12.1001 7.2002 11.742 7.2002 11.3001V7.70015C7.2002 7.25832 7.55837 6.90015 8.0002 6.90015ZM8.9002 5.30015C8.9002 5.7972 8.49726 6.20015 8.0002 6.20015C7.50315 6.20015 7.1002 5.7972 7.1002 5.30015C7.1002 4.80309 7.50315 4.40015 8.0002 4.40015C8.49726 4.40015 8.9002 4.80309 8.9002 5.30015Z"
      fill="#6F7071"
    />
  </svg>
);
export default InfoCircleIcon;
