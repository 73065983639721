import { DATA_RESULT_MODAL } from "constant/data-result-modal";
import { IResultModal } from "constant/TypeData";
import { atom } from "recoil";

export const resultModalState = atom<IResultModal>({
  key: "resultModaldState",
  default: {
    info: DATA_RESULT_MODAL.CANCEL_BASIC_SUCCESS,
    handleTouchButton: undefined,
    visible: false,
  },
});
