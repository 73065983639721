import "./style.scss";

import Button from "components/button";
import Counter from "components/counter";
import Image from "components/image";
import Input from "components/input";
import Label from "components/label";
import Section from "components/section";
import Spin from "components/spin";
import Text from "components/text";
import Title from "components/title";
import { DESKTOP_SIZE, Z_BUSINESS } from "constant/home";
import { useAppSelector } from "hooks";
import useWindowSize from "hooks/use-window-size";
import useNotify from "hooks/useNotify";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useSearchParams } from "react-router-dom";
import home from "services/home";
import { CODE } from "services/http-common";
import { RootState } from "store";
import formatCurrency from "utils/format-currency";
import { setTrackingReferer } from "utils/helper";
import loginModal from "utils/login-modal";
import { ProductI } from "z-types/home";

const baseStyle = "code";
const basePackageStyle = "code__packages";
const ENABLE_DISCOUNT = false;
const MIN_CODE = 1;
const MAX_CODE = 9;
const schemaPage = {
  "@context": "https://schema.org",
  "@type": "WebPage",
  url: "https://business.zbox.vn/mua-code",
  name: "Mua code nâng cấp tài khoản zBusiness Pro",
  description:
    "Mua Zalo Business Pro nâng cấp và bổ sung các tính năng giúp người dùng làm việc thuận tiện hơn trên Zalo",
};

const CodePage: React.FC = () => {
  const { setShowNotifyModal, setShowCustomModal } = useNotify();
  const { profile } = useAppSelector((state: RootState) => state.profile);
  const { openModal } = loginModal();
  const [quantity, setQuantity] = useState<number>(1);
  const [discountPrice] = useState<number>(0);
  const [isRequestPayment, setIsRequestPayment] = useState<boolean>(false);
  const [errorCodeResp, setErrorCodeResp] = useState<string>("");
  const proPackage =
    Z_BUSINESS.packages.find((pkg) => pkg.packageId === "pro") ||
    Z_BUSINESS.packages[Z_BUSINESS.packages.length - 1];

  const { subscription } = useAppSelector(
    (state: RootState) => state.subscription
  );
  const [productSelected, setProductSelected] = useState<ProductI>(
    proPackage.products[0]
  );

  const [searchParams] = useSearchParams();

  const { width } = useWindowSize();
  const isDesktop = useMemo(() => width > DESKTOP_SIZE, [width]);

  useEffect(() => {
    const resp = searchParams.get("resp");
    if (resp) {
      setErrorCodeResp(resp);
    }
    setTrackingReferer();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const onSelectProduct = useCallback(
    (product: ProductI) => {
      if (productSelected?.productId !== product.productId) {
        setProductSelected(product);
      }
    },
    [productSelected]
  );

  useEffect(() => {
    if (errorCodeResp && profile) {
      checkErrorCodePayment();
    }
  }, [errorCodeResp, profile]);

  const checkErrorCodePayment = useCallback(async () => {
    if (!errorCodeResp) return;
    const checkErrorCodePaymentResp = await home.checkErrorCodePayment({
      params: errorCodeResp,
      userId: profile?.userId || "",
    });

    if (checkErrorCodePaymentResp.error_code === 0) {
      handleErrorNotification(checkErrorCodePaymentResp.data.errorCode);
    }
  }, [errorCodeResp, profile]);

  useEffect(() => {
    if (subscription) {
      setProductSelected(
        proPackage.products.filter((prod) => !prod.hidden && prod.canBuyCode)[0]
      );
    }
  }, [subscription]);

  const handleErrorNotification = (errorCode: number) => {
    switch (errorCode) {
      case -2004:
      case -2005:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/25/zds_il_user_account_ekyc_warning_1711354288375_317079.png"
                width={220}
                height={220}
              />
              <Text center size="xlg-18">
                Bạn cần mở ứng dụng Zalo trên thiết bị di động, kiểm tra tin
                nhắn từ <strong>OA Zalo Định Danh Tài Khoản</strong> và làm theo
                hướng dẫn.
              </Text>
            </div>
          ),
        });
        break;
      case -2006:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Mua code tài khoản không thành công
              </Text>
              <Text center size="xlg-18">
                Tài khoản của bạn chưa đủ điều kiện để mua code tài khoản
                zBusiness
              </Text>
            </div>
          ),
        });
        break;
      case -2008:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Mua code tài khoản không thành công
              </Text>
              <Text center size="xlg-18">
                Tài khoản của bạn đang sử dụng dịch vụ zStyle nên chưa thể mua
                code zBusiness. Chúng tôi sẽ hỗ trợ trong thời gian sớm nhất.
              </Text>
            </div>
          ),
        });
        break;
      case -2011:
        setShowCustomModal({
          title: "zBusiness Pro",
          description: (
            <div className="notify-modal">
              <Text
                center
                size="xlg-28"
                className="notify-modal__title_no_icon"
                weight="three-quarters-bold"
              >
                Bạn muốn nâng cấp lên <br /> tài khoản zBusiness Pro?
              </Text>
              <Text center size="xlg-18">
                Tài khoản của bạn chưa đủ điều kiện để kích hoạt zBusiness Pro.
                Bạn vẫn có thể mua và kích hoạt code cho tài khoản khác.
              </Text>
            </div>
          ),
          okBtn: (
            <Button
              type="primary"
              size="large"
              block
              key="register-code"
              onClick={() => {
                handleBuyProduct(true);
                setShowCustomModal(null);
              }}
            >
              {isRequestPayment ? <Spin isSmall /> : `Tiếp tục mua`}
            </Button>
          ),
        });
        break;
      case -2012:
        setShowCustomModal({
          title: "zBusiness Pro",
          description: (
            <div className="notify-modal">
              <Text
                center
                size="xlg-28"
                className="notify-modal__title_no_icon"
                weight="three-quarters-bold"
              >
                Bạn có thể mua code nhưng chưa đủ điều kiện kích hoạt
              </Text>
              <Text center size="xlg-18">
                Bạn sẽ cần định danh tài khoản Zalo trước khi kích hoạt code cho
                tài khoản của mình
              </Text>
            </div>
          ),
          okBtn: [
            <Button
              type="primary"
              size="large"
              block
              key="register-code"
              onClick={() => {
                handleBuyProduct(true);
                setShowCustomModal(null);
              }}
            >
              {isRequestPayment ? <Spin isSmall /> : `Đã hiểu, tiếp tục mua`}
            </Button>,
            <Button
              size="large"
              type="ghost-primary"
              block
              key="register-code-back"
              onClick={() => {
                handleBuyProduct(false, true);
                setShowCustomModal(null);
              }}
            >
              {`Tôi muốn định danh tài khoản trước`}
            </Button>,
          ],
        });
        break;
      default:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đã có lỗi xảy ra!!!
              </Text>
              <Text center size="xlg-18">
                Vui lòng thử lại.
              </Text>
            </div>
          ),
        });
        break;
    }
  };

  const handleBuyProduct = useCallback(
    async (confirm = false, sendOA = false) => {
      if (isRequestPayment) return;
      if (quantity < MIN_CODE || quantity > MAX_CODE) return;

      if (!confirm) {
        setIsRequestPayment(true);
      }

      if (!productSelected) return;

      const { productId } = productSelected;

      try {
        home.trackingLog({
          flow: "Payment",
          action: "click_button_choose_package",
          params: JSON.stringify({
            package_id: productId,
            type: "code",
            code_count: quantity,
          }),
        });
        const requestPaymentResp = await home.requestCodeProductPayment({
          productId,
          qty: quantity,
          userId: profile?.userId || "",
          confirm,
          sendOA,
        });

        if (requestPaymentResp?.error_code !== CODE.SUCCESS) {
          setIsRequestPayment(false);

          if (
            requestPaymentResp?.error_code === -200 &&
            requestPaymentResp.data
          ) {
            onLogin(requestPaymentResp.data);
            return;
          }

          home.trackingLog({
            flow: "Payment",
            action: "click_button_choose_package_fail",
            params: JSON.stringify({
              fail_reason: `${requestPaymentResp?.error_code}`,
              type: "code",
            }),
          });

          handleErrorNotification(requestPaymentResp?.error_code);
          return;
        }

        setIsRequestPayment(false);

        window.location.href = requestPaymentResp.data;
      } catch (error) {
        home.trackingLog({
          flow: "Payment",
          action: "click_button_choose_package_fail",
          params: JSON.stringify({
            fail_reason: `unknown_error`,
            error,
            type: "code",
          }),
        });
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đã có lỗi xảy ra!!!
              </Text>
              <Text center size="xlg-18">
                Vui lòng thử lại.
              </Text>
            </div>
          ),
        });
        setIsRequestPayment(false);
      }
    },
    [productSelected, profile, isRequestPayment, quantity]
  );

  const onLogin = (redirect: string): void => {
    openModal({
      title: "Đăng nhập để tiếp tục",
      description: "Vui lòng đăng nhập tài khoản Zalo của bạn để tiếp tục.",
      okText: "Đăng nhập",
      onOk: async function () {
        const params = {
          from: "choose_package",
        };
        home.trackingLog({
          flow: "Onboarding",
          action: "click_button_sign_in",
          params: JSON.stringify(params),
        });

        window.location.href = redirect
          ? `${import.meta.env.VITE_ZBOX_LOGIN}?redirect=${redirect}`
          : import.meta.env.VITE_ZBOX_LOGIN!;
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>Mua code nâng cấp tài khoản zBusiness Pro</title>
        <meta
          name="description"
          content="Mua Zalo Business Pro nâng cấp và bổ sung các tính năng giúp người dùng làm việc thuận tiện hơn trên Zalo"
        />
        <script type="application/ld+json">{JSON.stringify(schemaPage)}</script>
      </Helmet>
      <Section
        name="introduce"
        title={<Title size="h1">Mua code tài khoản zBusiness Pro</Title>}
        btmDescription={
          <Text
            size="xlg-18"
            color="cod-grey"
            style={{ maxWidth: 478, display: "block" }}
          >
            Nâng cấp và bổ sung các tính năng <br /> giúp người dùng làm việc
            thuận tiện hơn trên Zalo
          </Text>
        }
      ></Section>
      <div className={baseStyle}>
        <div className={basePackageStyle}>
          <div className={`${basePackageStyle}__info_cont`}>
            <div className={`${basePackageStyle}__selection`}>
              {proPackage.products
                .filter((prod) => !prod.hidden && prod.canBuyCode)
                .map((product: ProductI) => (
                  <div
                    key={product.productId}
                    className={
                      productSelected?.productId === product.productId
                        ? `${basePackageStyle}__selection__option ${basePackageStyle}__selection__option--activate`
                        : `${basePackageStyle}__selection__option`
                    }
                    onClick={() => onSelectProduct(product)}
                  >
                    <div
                      className={`${basePackageStyle}__selection__option__left`}
                    >
                      <Text
                        color="grey"
                        weight="semi-bold"
                      >{`${product.duration} tháng`}</Text>
                      <div
                        className={`${basePackageStyle}__selection__option__left__price`}
                      >
                        <Text
                          size="xlg-24"
                          weight="semi-bold"
                          color={
                            productSelected?.productId === product.productId
                              ? "primary"
                              : undefined
                          }
                        >
                          {product.price}
                        </Text>
                        {product.savePercent && (
                          <Label
                            text={product.savePercent}
                            type="sale"
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          />
                        )}
                      </div>
                      {product.promotionText && (
                        <Text color="dove-gray" weight="semi-bold">
                          {product.promotionText}
                        </Text>
                      )}
                    </div>
                    {/* <Image
                      srcImage={
                        productSelected?.productId === product.productId
                          ? "https://res-zalo.zadn.vn/upload/media/2023/10/26/icon_blue_radio_1698310463932_565835.svg"
                          : "https://res-zalo.zadn.vn/upload/media/2023/10/26/icon_radio_1698310532882_576586.svg"
                      }
                    /> */}
                  </div>
                ))}
            </div>
            {isDesktop && (
              <div className={`${basePackageStyle}__contact`}>
                <div className={`${basePackageStyle}__contact_info`}>
                  Doanh nghiệp muốn mua 10 code trở lên vui lòng liên hệ{" "}
                  <a
                    className={`${basePackageStyle}__contact_cta`}
                    href="https://zalo.me/377233835127304759"
                    target="_blank"
                  >
                    Zalo OA Kênh Khách hàng Doanh nghiệp zBox
                  </a>
                </div>
              </div>
            )}
          </div>
          <div className={`${basePackageStyle}__buy_cont`}>
            <div className={`${basePackageStyle}__prices`}>
              <div className={`${basePackageStyle}__prices__card`}>
                <div className={`${basePackageStyle}__prices__row`}>
                  <Text size="md" weight="semi-bold">
                    Chọn số lượng
                  </Text>
                  <Counter
                    value={quantity}
                    onUpdate={setQuantity}
                    min={MIN_CODE}
                    max={MAX_CODE}
                    errorMessage={`Liên hệ Zalo OA khi mua ${
                      MAX_CODE + 1
                    } code trở lên`}
                  />
                </div>
                <hr className={`${basePackageStyle}__prices__separator`} />
                <div className={`${basePackageStyle}__prices__row__title`}>
                  <Text size="xlg-20" weight="semi-bold">
                    Tạm tính
                  </Text>
                </div>
                <div className={`${basePackageStyle}__prices__row__subrow`}>
                  <div className={`${basePackageStyle}__prices__row`}>
                    <Text color="grey">Đơn giá</Text>
                    <Text size="md" weight="semi-bold">
                      {productSelected.price}
                    </Text>
                  </div>
                  <div className={`${basePackageStyle}__prices__row`}>
                    <Text color="grey">Số lượng</Text>
                    <Text size="md" weight="semi-bold">
                      {quantity}
                    </Text>
                  </div>
                  <div className={`${basePackageStyle}__prices__row`}>
                    <Text color="grey">Thành tiền</Text>
                    <Text size="md" weight="semi-bold">
                      {formatCurrency(
                        "vi",
                        productSelected.rawPrice * quantity
                      )}
                    </Text>
                  </div>
                </div>
                {ENABLE_DISCOUNT && (
                  <>
                    <hr className={`${basePackageStyle}__prices__separator`} />
                    <div className={`${basePackageStyle}__prices__row__subrow`}>
                      <div className={`${basePackageStyle}__prices__row`}>
                        <Input
                          placeholder="Mã giới thiệu"
                          suffix={<>Áp dụng</>}
                        />
                      </div>
                      {discountPrice > 0 && (
                        <div className={`${basePackageStyle}__prices__row`}>
                          <Text color="grey">Giảm giá</Text>
                          <Text size="md" weight="semi-bold">
                            {"-" + formatCurrency("vi", discountPrice)}
                          </Text>
                        </div>
                      )}
                    </div>
                  </>
                )}
                <hr
                  className={`${basePackageStyle}__prices__separator__final`}
                />
                <div className={`${basePackageStyle}__prices__row`}>
                  <Text color="grey">Tổng thanh toán</Text>
                  <Text size="md" weight="semi-bold">
                    {formatCurrency(
                      "vi",
                      productSelected.rawPrice * quantity - discountPrice
                    )}
                  </Text>
                </div>
              </div>
            </div>
            <Button
              type={
                quantity < MIN_CODE || quantity > MAX_CODE
                  ? "disable"
                  : "primary"
              }
              size="large"
              block
              key="register-code"
              onClick={() => handleBuyProduct()}
              disabled={quantity < MIN_CODE || quantity > MAX_CODE}
            >
              {isRequestPayment ? <Spin isSmall /> : `Mua code`}
            </Button>
          </div>
          {!isDesktop && (
            <div className={`${basePackageStyle}__contact`}>
              <div className={`${basePackageStyle}__contact_info`}>
                Doanh nghiệp muốn mua 10 code trở lên vui lòng liên hệ{" "}
                <a
                  className={`${basePackageStyle}__contact_cta`}
                  href="https://zalo.me/377233835127304759"
                  target="_blank"
                >
                  Zalo OA Kênh Khách hàng Doanh nghiệp zBox
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CodePage;
