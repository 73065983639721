import BlueButton from "components/blue-button";
import Image from "components/image";
import ManageBenefitItem from "components/manage-benefit-item";
import Text from "components/text";
import { DATA_RESULT_MODAL } from "constant/data-result-modal";
import { TRIAL_PRODUCT_ID } from "constant/home";
import { TYPE_USER } from "constant/TypeData";
import { useAppSelector } from "hooks";
import { useActivePackageInfo } from "hooks/useActivePackage";
import { useLoading } from "hooks/useLoading";
import useNotify from "hooks/useNotify";
import { useResultModal } from "hooks/useResultModal";
import BlueAutoReplyIcon from "icons/BlueAutoReplyIcon";
import BlueCatalogIcon from "icons/BlueCatalogIcon";
import BlueGroupIcon from "icons/BlueGroupIcon";
import BlueLinkIcon from "icons/BlueLinkIcon";
import BlueQuickReplyIcon from "icons/BlueQuickReplyIcon";
import Alert from "images/Alert";
import UpgradeBasicIcon from "images/UpgradeBasicIcon";
import { useTranslation } from "react-i18next";
import home from "services/home";
import { CODE } from "services/http-common";
import { RootState } from "store";
import { convertTimestampToDateString } from "utils/helper";

const owlClass = "manage-basic";

const ADVANTAGE_UPGRADE = [
  {
    icon: <BlueGroupIcon />,
    contentKey: "community-benefit-basic-package",
  },

  {
    icon: <BlueQuickReplyIcon />,
    contentKey: "fast-message-benefit-basic-package",
  },

  {
    icon: <BlueAutoReplyIcon />,
    contentKey: "auto-message-benefit-basic-package",
  },
  {
    icon: <BlueLinkIcon />,
    contentKey: "link-benefit-basic-package",
  },
  {
    icon: <BlueCatalogIcon />,
    contentKey: "category-benefit-basic-package",
  },
] as const;

const UpgradeBasic: React.FC = () => {
  // const [isRequestPayment, setIsRequestPayment] = useState<boolean>(false);
  // const isRequestPaymentRef = useRef<boolean>(false);
  const { setInfoResultModal } = useResultModal();
  const { profile } = useAppSelector((state: RootState) => state.profile);
  const { setShowNotifyModal } = useNotify();

  const { showLoading, hideLoading } = useLoading();

  const { activePackageInfo } = useActivePackageInfo();
  const { cooldownBasic, typeUser } = activePackageInfo || {
    cooldownBasic: 0,
    typeUser: TYPE_USER.NORMAL,
  };

  const isFreeze = +cooldownBasic > 0;

  const { t } = useTranslation();

  const handleBuyTrial = async () => {
    if (typeUser === TYPE_USER.BASIC || typeUser === TYPE_USER.PRO) {
      setInfoResultModal({
        visible: true,
        info:
          typeUser === TYPE_USER.BASIC
            ? DATA_RESULT_MODAL.UPGRADE_FAIL_BECAUSE_BASIC_ACCOUNT
            : DATA_RESULT_MODAL.UPGRADE_FAIL_BECAUSE_PRO_ACCOUNT,
      });
      return;
    }

    showLoading();

    try {
      const requestPaymentResp = await home.requestProductPayment({
        userId: profile?.userId || "",
        productId: TRIAL_PRODUCT_ID,
      });

      if (requestPaymentResp?.error_code !== CODE.SUCCESS) {
        handleErrorCase(requestPaymentResp?.error_code);

        hideLoading();
        home.trackingLog({
          flow: "Upgrade",
          action: "click_activate_basic_fail",
          params: JSON.stringify({
            error_code: requestPaymentResp?.error_code,
          }),
        });
        return;
      }
      hideLoading();

      setInfoResultModal((prev) => ({
        ...prev,
        visible: true,
        info: DATA_RESULT_MODAL.UPGRADE_BASIC_SUCCESS,
      }));

      home.trackingLog({
        flow: "Upgrade",
        action: "click_activate_basic_success",
      });
    } catch (error) {
      hideLoading();

      setShowNotifyModal({
        description: (
          <div className="notify-modal">
            <Alert></Alert>
            <Text
              center
              size="xlg-24"
              className="notify-modal__title"
              weight="three-quarters-bold"
            >
              {t("error-occur")}
            </Text>
            <Text center size="xlg-18">
              {t("please-try-again")}
            </Text>
          </div>
        ),
      });
    }

    home.trackingLog({
      flow: "Upgrade",
      action: "click_activate_basic",
    });
  };

  const handleErrorCase = (errorCode: number) => {
    switch (errorCode) {
      case -2001:
        setInfoResultModal({
          visible: true,
          info: DATA_RESULT_MODAL.UPGRADE_BASIC_FAIL,
        });
        break;
      case -2003:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                {t("upgrade-fail")}
              </Text>
              <Text center size="xlg-18">
                Bạn có thể chờ hết hạn gói hiện tại để đăng ký gói này
              </Text>
            </div>
          ),
        });
        break;
      case -2004:
        setInfoResultModal({
          visible: true,
          info: DATA_RESULT_MODAL.NOT_YET_EKYC_UPGRADE_BASIC,
        });
        break;
      case -2005:
        setInfoResultModal({
          visible: true,
          info: DATA_RESULT_MODAL.ALREADY_YET_EKYC_UPGRADE_BASIC,
        });
        break;
      case -2008:
        setInfoResultModal({
          visible: true,
          info: DATA_RESULT_MODAL.UPGRADE_BASIC_FAIL_BECAUSE_ZSTYLE,
        });
        break;

      case -2009:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đăng ký không thành công
              </Text>
              <Text center size="xlg-18">
                Bạn không nằm trong danh sách giới hạn được trải nghiệm chương
                trình
              </Text>
            </div>
          ),
        });
        break;
      case -2010:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Thực hiện không thành công
              </Text>
              <Text center size="xlg-18">
                Bạn đã mua gói dùng thử zBusiness Pro trước đó
              </Text>
            </div>
          ),
        });
        break;
      case -2013:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Thực hiện không thành công
              </Text>
              <Text center size="xlg-18">
                Bạn đã mua gói ưu đãi zBusiness Pro trước đó
              </Text>
            </div>
          ),
        });
        break;
      default:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đã có lỗi xảy ra!!!
              </Text>
              <Text center size="xlg-18">
                Vui lòng thử lại.
              </Text>
            </div>
          ),
        });
        break;
    }
  };

  return (
    <div className={`${owlClass}__bg--gradient ${owlClass}__bg `}>
      <div className={`${owlClass}__container`}>
        <div>
          <UpgradeBasicIcon></UpgradeBasicIcon>
        </div>

        <h1 className={`${owlClass}__title`} style={{ fontFamily: "Inter" }}>
          {t("title-upgrade-basic")}
        </h1>

        <div className={`${owlClass}__container-list`}>
          <p className={`${owlClass}__container-list__title`}>
            {t("benefit-basic-package")}
          </p>
          <ul
            className={`${owlClass}__list-item upgrade-special-list`}
            style={{ marginTop: "16px", gap: "12px" }}
          >
            {ADVANTAGE_UPGRADE.map(({ icon, contentKey }, index) => (
              <ManageBenefitItem
                key={index}
                icon={icon}
                content={t(contentKey)}
              ></ManageBenefitItem>
            ))}
          </ul>
        </div>

        <div className={`${owlClass}__footer`}>
          {isFreeze && (
            <p
              className={`${owlClass}__notice-text`}
              style={{ textAlign: "center" }}
              dangerouslySetInnerHTML={{
                __html: t("disclaimer-cancel-basic", {
                  date: convertTimestampToDateString(+cooldownBasic),
                }),
              }}
            />
          )}

          <BlueButton
            disabled={isFreeze}
            content={t("upgrade-basic")}
            customStyleContainer={{
              width: "100%",
            }}
            handleTouchButton={() => {
              handleBuyTrial();
            }}
          ></BlueButton>
        </div>
      </div>
    </div>
  );
};

export default UpgradeBasic;
