import CancelBasic from "images/CancelBasic";
import CommunityCongratulation from "images/CommunityCongratulation";
import ContinueBasic from "images/ContinueBasic";
import Phone from "images/Phone";
import Warning from "images/Warning";
import WarningEKYC from "images/WarningEKYC";

export const DATA_RESULT_MODAL = {
  UPGRADE_BASIC_SUCCESS: {
    img: <CommunityCongratulation></CommunityCongratulation>,
    title: "upgrade-success",
    desc: "desc-upgrade-basic-success",
  },

  UPGRADE_BASIC_FAIL: {
    img: <Warning />,
    title: "upgrade-fail",
    desc: "desc-upgrade-basic-fail",
  },

  UPGRADE_BASIC_FAIL_BECAUSE_ZSTYLE: {
    img: <Warning />,
    title: "upgrade-fail",
    desc: "use-zstyle-can-not-upgrade-basic",
  },

  NOT_YET_EKYC_UPGRADE_BASIC: {
    img: <WarningEKYC />,
    title: "need-ekyc-to-continue",
    desc: "desc-guide-ekyc",
  },

  ALREADY_YET_EKYC_UPGRADE_BASIC: {
    img: <WarningEKYC />,
    title: "need-re-ekyc-to-continue",
    desc: "desc-guide-ekyc",
  },

  NOT_BASIC_USER_FOR_DOWNGRADE: {
    img: <Warning />,
    title: "can-not-cancel-basic",
    desc: "desc-can-not-cancel-basic",
  },

  UPGRADE_FAIL_BECAUSE_BASIC_ACCOUNT: {
    img: <Warning />,
    title: "upgrade-fail",
    desc: "basic-can-not-upgrade-basic",
  },

  UPGRADE_FAIL_BECAUSE_PRO_ACCOUNT: {
    img: <Warning />,
    title: "upgrade-fail",
    desc: "pro-can-not-upgrade-basic",
  },

  ALREADY_BASIC_FOR_UPGRADE: {
    img: <Warning />,
    title: "can-not-upgrade-basic",
    desc: "desc-already-basic-for-upgrade",
  },

  ALREADY_PRO_FOR_UPGRADE: {
    img: <Warning />,
    title: "can-not-upgrade-basic",
    desc: "desc-already-pro-for-upgrade",
  },

  NOT_OPEN_IN_ZALO_APP: {
    img: <Phone />,
    title: "please-use-phone-for-using",
    desc: "please-open-zalo-app",
  },

  CANCEL_BASIC_SUCCESS: {
    img: <CancelBasic></CancelBasic>,
    title: "cancel-successful",
    desc: "Bạn đã trở về tài khoản thường và không thể tiếp tục sử dụng các đặc quyền của gói Basic",
  },

  CANCEL_BASIC_FAIL: {
    img: <Warning />,
    title: "error-occur",
    desc: "please-try-again",
  },

  CONTINUE_BASIC: {
    img: <ContinueBasic></ContinueBasic>,
    title: "short-continue-basic-plan",
    desc: "desc-continue-basic",
  },
} as const;
