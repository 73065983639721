import { IUpgradeProModalState } from "constant/TypeData";
import { atom } from "recoil";

export const upgradeProModalState = atom<IUpgradeProModalState>({
  key: "upgradeProModalState",
  default: {
    data: {
      source: "landing-page",
      flow: "Onboarding",
    },
    visible: false,
  },
});
