const CommunityCongratulation = () => (
  <svg
    width={128}
    height={128}
    viewBox="0 0 128 128"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M98.6228 87.9972C96.0348 85.6586 93.6858 83.7722 92.0385 82.8108C85.4333 78.96 77.6797 78.7573 73.2884 79.277C72.6077 79.3601 72.01 79.4537 71.5111 79.5576C71.1058 79.6408 70.768 79.7291 70.503 79.8071C67.7643 80.6645 66.2156 75.2183 70.1132 73.176C74.0108 71.1388 76.9366 71.0921 77.0821 69.9799C77.1185 69.7305 76.7443 69.2836 76.1259 68.6859C74.0368 66.6852 69.3181 62.8759 66.9276 58.9212C63.8251 53.7919 65.2906 51.5521 66.6885 51.0428C67.0419 50.9181 67.3797 50.7986 67.7331 50.757H67.7435C68.8036 50.6531 70.0301 51.2715 72.2283 54.5351C73.5847 56.541 75.1073 58.1884 76.4325 59.4356C73.5743 55.0444 70.3678 47.0517 72.3063 44.5572C72.67 44.0895 73.065 43.7725 73.4599 43.6114C74.4213 43.2061 75.4035 43.679 76.0323 44.9834C76.6871 46.3501 78.5008 50.2893 80.4652 53.5945C80.4392 53.5425 80.4132 53.4853 80.3925 53.423C79.1556 50.5336 78.8438 44.6872 80.5536 43.5958C81.0888 43.2529 81.5254 43.1125 81.8995 43.1593C82.8973 43.284 83.4586 44.7963 84.3212 47.6441C85.6984 52.1705 87.6628 56.2084 87.6628 56.2084V56.1981C87.668 56.1357 87.6887 55.5121 87.7199 53.4905C87.7719 49.9359 87.0911 47.7689 88.1928 46.0539C88.7021 45.264 89.5232 45.0717 90.2767 45.4043C90.8952 45.6798 91.1966 46.2514 91.3889 47.0985C93.8677 57.9754 96.3414 62.3614 100.208 66.0823C103.071 68.8418 109.453 73.732 115.564 77.9466C116.453 66.4669 116.183 58.0013 115.138 48.5744C113.117 30.318 102.209 22.6528 85.2203 20.439C71.7814 18.6824 52.5221 17.7522 33.0861 20.5585C13.1304 23.4375 9.23802 35.7643 7.87646 51.2975C6.5149 66.8359 6.72278 72.3445 7.87646 85.1753C9.74211 105.807 17.4489 111.653 37.3059 112.796C56.0039 113.872 69.5571 113.82 85.8283 112.796C88.0733 112.656 90.2196 112.329 92.2567 111.835C90.7445 109.262 89.8766 106.264 89.8766 103.073C89.8662 96.6291 93.3948 90.9958 98.6228 87.9972Z"
      fill="url(#paint0_linear_4181_15266)"
    />
    <path
      d="M91.3893 47.1032C91.197 46.2509 90.8956 45.6793 90.2772 45.409C89.5288 45.0816 88.7026 45.2687 88.1933 46.0586C87.0915 47.7736 87.7723 49.9406 87.7204 53.4952C87.6892 55.5168 87.6684 56.1352 87.6632 56.2028V56.2132C87.6632 56.2132 85.6988 52.1752 84.3217 47.6488C83.4642 44.8062 82.9029 43.2887 81.9 43.164C81.5258 43.1172 81.0893 43.2576 80.554 43.6005C78.8442 44.6919 79.1561 50.5383 80.3929 53.4277C80.4137 53.49 80.4397 53.5472 80.4656 53.5992C78.5013 50.294 76.6876 46.3548 76.0328 44.9881C75.4092 43.6889 74.4218 43.216 73.4604 43.6161C73.0654 43.7772 72.6653 44.0942 72.3067 44.562C70.3683 47.0564 73.5747 55.0491 76.4329 59.4404C75.1077 58.1931 73.5799 56.5457 72.2287 54.5398C70.0305 51.2762 68.804 50.6578 67.7439 50.7617H67.7335C67.3801 50.8033 67.0423 50.9228 66.689 51.0475C65.291 51.5568 63.8255 53.7966 66.928 58.9259C69.3185 62.8806 74.0372 66.6899 76.1263 68.6906C76.7499 69.2831 77.1189 69.73 77.0825 69.9846C76.937 71.0968 74.0112 71.1435 70.1136 73.1807C66.216 75.223 67.7647 80.6692 70.5034 79.8118C70.7684 79.7286 71.1062 79.6403 71.5116 79.5623C72.0105 79.4584 72.6081 79.3648 73.2889 79.2817C77.6802 78.762 85.4338 78.9647 92.0389 82.8155C93.6863 83.7769 96.0352 85.6633 98.6232 88.0019C101.164 86.5416 104.111 85.7049 107.245 85.7049C109.833 85.7049 112.291 86.2766 114.499 87.3003C114.749 85.9907 114.952 84.6396 115.092 83.2365C115.274 81.4072 115.43 79.6507 115.56 77.9669C109.448 73.7523 103.066 68.8621 100.203 66.1026C96.3418 62.3661 93.863 57.9801 91.3893 47.1032Z"
      fill="url(#paint1_linear_4181_15266)"
    />
    <path
      d="M66.1012 23.9237C66.9326 23.9237 66.9326 22.6245 66.1012 22.6245C65.2697 22.6245 65.2697 23.9237 66.1012 23.9237Z"
      fill="url(#paint2_linear_4181_15266)"
    />
    <path
      d="M93.4883 103.072C93.4883 110.654 99.6517 116.827 107.244 116.827C114.826 116.827 121 110.654 121 103.072C121 95.4843 114.826 89.3157 107.244 89.3157C99.6569 89.3157 93.4883 95.4895 93.4883 103.072Z"
      fill="url(#paint3_linear_4181_15266)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M114.358 97.7293C115.309 98.462 115.486 99.8236 114.758 100.775L107.555 110.144L107.535 110.17C106.708 111.184 105.144 111.371 104.11 110.409C104.11 110.409 104.11 110.409 104.105 110.404L99.9682 106.569C99.0899 105.753 99.038 104.381 99.8487 103.498C100.665 102.619 102.037 102.567 102.92 103.383L105.461 105.732L111.308 98.1242C112.04 97.1784 113.407 96.9965 114.358 97.7293Z"
      fill="white"
    />
    <path
      d="M48.2714 69.6512L38.0337 56.7579C38.0337 56.7579 18.8056 57.948 7.22192 59.8396C6.67626 68.7989 6.92051 74.2555 7.70002 83.2044C20.6192 80.1175 48.2662 69.6564 48.2714 69.6512Z"
      fill="#FFECDA"
    />
    <path
      d="M48.2609 69.6569L38.0232 56.7637C38.0232 56.7637 18.9718 57.9018 7.31536 59.835L7.13867 66.5492C7.253 66.5336 7.36733 66.5128 7.47646 66.4973C14.9546 65.4215 28.7002 65.9204 36.9423 73.7936C43.2979 71.5537 48.2661 69.6569 48.2609 69.6569Z"
      fill="url(#paint4_linear_4181_15266)"
    />
    <path
      d="M44.4525 70.3999C38.2787 70.6961 35.7842 67.2195 33.0507 63.457C30.4627 59.8868 28.4412 59.0657 25.6297 56.9558C22.5896 54.6744 24.5436 50.9639 28.436 52.0708C30.5822 52.684 32.7857 53.8013 34.8592 55.1733C37.1146 56.67 37.0003 55.059 36.2883 52.1644C35.4256 48.6201 33.7731 44.7174 31.0188 38.7566C28.7789 33.9184 34.5786 32.0268 37.2289 36.9741C39.3856 40.9965 40.2015 43.5637 41.3396 46.9468C41.5527 42.0514 40.742 36.3609 39.5207 32.349C38.3047 28.3371 42.7947 27.3861 44.915 30.3742C46.5624 32.7076 47.9239 41.3654 47.9395 44.4783C49.5609 41.5473 51.0784 37.0365 51.9878 33.3208C52.7673 30.1404 57.0911 30.6808 56.8832 35.5346C56.6753 40.3936 53.7235 47.2586 53.6716 48.1265C53.6248 48.9943 54.0457 48.8748 55.7919 46.7597C57.3197 44.9148 60.8172 39.7596 63.5974 41.4798C65.91 42.9089 59.5595 50.1116 58.2395 53.9936C56.9819 57.7041 57.6315 64.5691 53.4325 67.4585C49.8104 69.9582 46.2402 70.3012 44.4369 70.3843L44.4525 70.3999Z"
      fill="#FFECDA"
    />
    <path
      d="M47.2158 68.1868C50.5002 59.6849 44.1861 52.8927 38.2773 56.1147L47.2158 68.1868Z"
      fill="url(#paint5_linear_4181_15266)"
    />
    <path
      d="M71.7297 101.627C73.3407 100.816 72.3222 97.719 69.2665 97.428V97.4228C66.0808 97.1162 63.6695 99.673 61.4765 98.8363C59.2834 97.9996 61.3777 93.3693 61.9442 87.2683C62.4223 82.1754 62.0377 78.018 60.2085 77.1449C58.639 76.3966 57.7192 78.1167 57.4957 81.2816C57.0384 87.762 55.9419 90.8592 55.9419 90.8592C55.9419 90.8592 56.1498 85.7664 55.417 79.4315C55.2455 77.9868 54.6167 74.8271 52.1015 74.8271C49.6434 74.8323 50.5476 78.0751 50.4437 82.3521C50.267 89.638 49.6746 91.9194 49.6746 91.9194C49.6642 88.9624 49.2328 83.1888 48.0064 80.0135C47.2113 77.9608 45.2781 77.2125 44.0672 77.7685C43.2202 78.1583 42.7317 79.1873 43.3397 82.8042C43.8646 85.9275 44.1868 88.7909 44.0412 91.8726C43.5943 90.7501 42.9447 89.6796 42.1756 88.3336C40.6373 85.6417 38.5067 84.41 37.2283 86.2289C36.324 87.5177 38.4547 89.8147 39.8786 94.2527C42.0145 100.905 41.6559 107.359 40.3879 112.956C47.3984 113.325 53.7073 113.522 59.7667 113.564C62.6094 105.769 67.1514 103.934 71.7297 101.627Z"
      fill="url(#paint6_linear_4181_15266)"
    />
    <path
      d="M62.1312 83.0176C62.1312 83.0176 62.0533 81.8691 61.0607 80.6479C60.0681 79.4267 58.7117 79.2448 57.5996 80.3309L57.636 80.0503C57.9946 77.6909 58.8624 76.5061 60.2136 77.1505C61.4972 77.7637 62.0688 79.9879 62.1312 83.0176Z"
      fill="url(#paint7_linear_4181_15266)"
    />
    <path
      d="M55.4685 79.867C55.1723 77.8662 53.2131 77.2894 52.5011 77.279C51.7528 77.2634 50.729 77.7467 50.3912 78.8796C50.3133 76.4371 50.3809 74.8365 52.1062 74.8313C54.6162 74.8313 55.2502 77.9909 55.4165 79.4357C55.4373 79.5812 55.4529 79.7267 55.4685 79.867Z"
      fill="url(#paint8_linear_4181_15266)"
    />
    <path
      d="M48.5678 81.8378C47.4557 80.5127 45.6836 80.0969 44.3792 80.1957C43.5633 80.258 43.106 80.7309 43.184 81.6871C42.8514 78.9744 43.3191 78.1221 44.0778 77.7792C45.2835 77.2231 47.2167 77.9766 48.0118 80.0242C48.2196 80.5646 48.4067 81.1779 48.5678 81.8378Z"
      fill="url(#paint9_linear_4181_15266)"
    />
    <path
      d="M41.6034 87.4347C41.1253 86.8007 39.7222 86.624 38.7816 87.0449C37.9709 87.4139 37.5603 88.5883 37.6694 89.0405C37.0822 87.7673 36.7808 86.8838 37.2329 86.2394C38.3606 84.6388 40.1379 85.4027 41.6034 87.4347Z"
      fill="url(#paint10_linear_4181_15266)"
    />
    <path
      d="M71.7352 101.627C71.5689 101.71 71.4078 101.793 71.2415 101.876C71.2363 100.915 70.2645 99.839 68.3209 99.548C65.8681 99.179 64.0232 99.9534 62.0068 98.9764C64.0596 99.2934 66.3358 97.1419 69.2719 97.4277C72.3225 97.7239 73.341 100.816 71.7352 101.627Z"
      fill="url(#paint11_linear_4181_15266)"
    />
    <path
      d="M91.3883 47.1029C91.196 46.2507 90.8946 45.679 90.2762 45.4088C89.5279 45.0814 88.7016 45.2685 88.1923 46.0584C87.0906 47.7733 87.7713 49.9404 87.7194 53.495C87.6882 55.5165 87.6674 56.135 87.6622 56.2025V56.2129C87.6622 56.2129 85.6978 52.175 84.3207 47.6486C83.4632 44.806 82.902 43.2885 81.899 43.1638C81.5248 43.117 81.0883 43.2573 80.553 43.6003C78.8433 44.6916 79.1551 50.538 80.3919 53.4274C80.4127 53.4898 80.4387 53.547 80.4647 53.5989C78.5003 50.2938 76.6866 46.3546 76.0318 44.9878C75.4082 43.6886 74.4208 43.2157 73.4594 43.6159C73.0644 43.777 72.6643 44.094 72.3057 44.5617C70.3673 47.0562 73.5737 55.0488 76.432 59.4401C75.1068 58.1929 73.5789 56.5455 72.2278 54.5395C70.0295 51.276 68.8031 50.6575 67.7429 50.7615H67.7325C67.3791 50.8031 67.0414 50.9226 66.688 51.0473C65.29 51.5566 63.8245 53.7964 66.927 58.9256C69.3176 62.8804 74.0362 66.6896 76.1253 68.6904C76.749 69.2828 77.1179 69.7298 77.0816 69.9844C76.936 71.0965 74.0103 71.1433 70.1127 73.1804C66.2151 75.2228 67.7637 80.669 70.5024 79.8115C70.7675 79.7284 71.1052 79.64 71.5106 79.5621C72.0095 79.4581 72.6071 79.3646 73.2879 79.2815C77.6792 78.7618 85.4328 78.9644 92.0379 82.8153C93.6853 83.7767 96.0342 85.6631 98.6222 88.0017C101.163 86.5414 104.11 85.7047 107.244 85.7047C109.832 85.7047 112.29 86.2763 114.498 87.3001C114.748 85.9905 114.951 84.6393 115.091 83.2362C115.273 81.4069 115.429 79.6504 115.559 77.9667C109.447 73.7521 103.065 68.8619 100.202 66.1024C96.3409 62.3659 93.862 57.9798 91.3883 47.1029Z"
      fill="url(#paint12_linear_4181_15266)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M82.7352 57.3305C82.4182 56.4314 78.4062 44.9829 81.6231 43.1848C81.0878 43.268 80.8228 43.3563 80.2927 43.6993C78.5829 44.7906 78.8947 50.6318 80.1316 53.5264L80.2147 53.7135C80.2147 53.7135 80.2355 53.7395 80.2563 53.7811C80.3239 53.9058 80.4694 54.1708 80.672 54.5034C80.6772 54.5034 80.6876 54.5138 80.6876 54.519C80.724 54.5814 80.7604 54.6437 80.802 54.7113C81.3372 55.5895 82.1323 56.816 82.7508 57.3824C82.7508 57.3824 82.7508 57.3669 82.7352 57.3305ZM78.6452 61.7218C78.7305 61.8972 78.7998 62.0384 78.851 62.1426C78.9089 62.2607 78.9436 62.3313 78.9519 62.3506L76.182 59.6431L76.1716 59.6275C73.3133 55.2362 70.1069 47.2435 72.0453 44.7491C72.4091 44.2814 72.8041 43.9696 73.199 43.8033C70.9852 45.3727 77.1746 58.6505 78.6452 61.7218ZM74.1789 66.9624C71.7755 64.8856 68.4706 62.0299 66.6563 59.0246C63.559 53.8954 65.0193 51.6503 66.4173 51.1463L66.476 51.1255C66.8082 51.008 67.1281 50.8948 67.4618 50.8604H67.4722H67.493C63.683 53.7811 69.2235 61.888 76.1197 68.6854C75.6149 68.2032 74.9422 67.622 74.1789 66.9624ZM90.0263 45.5805C87.932 46.1573 88.1971 57.7877 88.1971 57.7877L87.3968 56.3066V56.2962C87.402 56.2287 87.4228 55.6103 87.4539 53.5887C87.4687 52.4682 87.4113 51.4862 87.3603 50.613C87.2493 48.7122 87.1684 47.3272 87.9268 46.1521C88.4361 45.3674 89.3352 45.1179 90.0263 45.5805ZM71.2399 79.6611C68.522 78.258 69.208 74.9892 72.8301 73.6224C73.4639 73.3845 74.0466 73.1847 74.5696 73.0054C77.0399 72.1582 78.1781 71.7679 77.0759 69.9795C76.997 70.5828 76.0217 70.9032 74.5235 71.3953C73.2602 71.8103 71.625 72.3475 69.842 73.2794C65.9444 75.3218 67.493 80.768 70.2317 79.9105C70.4968 79.8274 70.8398 79.7442 71.2399 79.6611Z"
      fill="url(#paint13_linear_4181_15266)"
    />
    <path
      d="M58.3829 28.4138C59.7214 28.4138 60.802 27.3232 60.802 25.9798C60.802 24.6365 59.7164 23.5459 58.3829 23.5459C57.0445 23.5459 55.9639 24.6365 55.9639 25.9798C55.9639 27.3232 57.0445 28.4138 58.3829 28.4138Z"
      fill="#F4C2FF"
    />
    <path
      d="M96.5732 33.7616C98.2041 29.2556 103.702 25.969 109.244 25.4287L111.95 28.6409C105.005 29.3101 100.831 32.4876 98.7494 37.0532L96.5732 33.7616Z"
      fill="#FFC261"
    />
    <path
      d="M104.741 40.924C106.08 40.924 107.16 39.8335 107.16 38.4901C107.16 37.1467 106.075 36.0562 104.741 36.0562C103.403 36.0562 102.317 37.1467 102.317 38.4901C102.317 39.8335 103.403 40.924 104.741 40.924Z"
      fill="#C0CBFF"
    />
    <path
      d="M98.436 9.98743C98.6342 14.1663 95.9376 20.6651 92.7402 23.451L94.5644 27.4117C94.5644 27.4117 101.752 21.9093 102.317 11.8563L98.436 9.98743Z"
      fill="#F4C2FF"
    />
    <path
      d="M71.0133 26.8464C61.3965 22.6924 67.4937 10.0666 58.6602 4.76248L62.2541 1.56018C71.0331 8.63399 65.5952 18.7068 74.1561 23.2575L71.0133 26.8464Z"
      fill="#C0CBFF"
    />
    <path
      d="M84.0309 25.3302C85.9047 23.1193 86.7326 18.073 84.1896 14.9798L79.4902 14.6278C81.3244 17.6269 81.3045 22.9409 79.5596 25.4294L84.0309 25.3302Z"
      fill="#9DF9DB"
    />
    <path
      d="M73.4826 37.2361C74.821 37.2361 75.9016 36.1455 75.9016 34.8021C75.9016 33.4587 74.816 32.3682 73.4826 32.3682C72.1441 32.3682 71.0635 33.4587 71.0635 34.8021C71.0635 36.1455 72.1491 37.2361 73.4826 37.2361Z"
      fill="#9DF9DB"
    />
    <path
      d="M86.7033 35.0149C88.0417 35.0149 89.1223 33.9243 89.1223 32.5809C89.1223 31.2375 88.0367 30.147 86.7033 30.147C85.3649 30.147 84.2842 31.2375 84.2842 32.5809C84.2792 33.9293 85.3649 35.0149 86.7033 35.0149Z"
      fill="#FFC261"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4181_15266"
        x1={81.4923}
        y1={18.7744}
        x2={12.0931}
        y2={118.869}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#339CFF" />
        <stop offset={1} stopColor="#005DE5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4181_15266"
        x1={124.676}
        y1={38.486}
        x2={30.7075}
        y2={120.783}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#339CFF" />
        <stop offset={1} stopColor="#1466DD" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4181_15266"
        x1={65.476}
        y1={23.2739}
        x2={66.7269}
        y2={23.2739}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2A74EA" />
        <stop offset={0.02} stopColor="#2B75EA" />
        <stop offset={0.38} stopColor="#398CF5" />
        <stop offset={0.71} stopColor="#429AFC" />
        <stop offset={1} stopColor="#459FFF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4181_15266"
        x1={107.244}
        y1={124.764}
        x2={110.415}
        y2={89.3157}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#11888F" />
        <stop offset={1} stopColor="#31DBBA" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4181_15266"
        x1={15.6817}
        y1={55.4242}
        x2={53.8067}
        y2={89.8586}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFECDA" />
        <stop offset={0.15} stopColor="#FCE6D4" />
        <stop offset={0.33} stopColor="#F5D8C5" />
        <stop offset={0.54} stopColor="#E9BFAC" />
        <stop offset={0.67} stopColor="#E0AC98" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4181_15266"
        x1={42.8283}
        y1={62.0937}
        x2={49.7998}
        y2={57.1391}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFECDA" />
        <stop offset={1} stopColor="#F6CFB8" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_4181_15266"
        x1={48.2427}
        y1={88.0329}
        x2={37.2271}
        y2={179.335}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7CEAF" />
        <stop offset={0.99} stopColor="#D68C67" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_4181_15266"
        x1={60.2611}
        y1={80.3235}
        x2={61.6751}
        y2={77.3919}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7CEAF" stopOpacity={0} />
        <stop offset={0.11} stopColor="#F8D3B6" stopOpacity={0.15} />
        <stop offset={0.28} stopColor="#FADCC1" stopOpacity={0.38} />
        <stop offset={0.45} stopColor="#FCE3CA" stopOpacity={0.56} />
        <stop offset={0.62} stopColor="#FDE9D1" stopOpacity={0.71} />
        <stop offset={0.76} stopColor="#FEEDD6" stopOpacity={0.81} />
        <stop offset={0.9} stopColor="#FEF0D9" stopOpacity={0.88} />
        <stop offset={1} stopColor="#FFF1DB" stopOpacity={0.9} />
      </linearGradient>
      <linearGradient
        id="paint8_linear_4181_15266"
        x1={52.8163}
        y1={77.0294}
        x2={52.6843}
        y2={74.3057}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7CEAF" stopOpacity={0} />
        <stop offset={0.11} stopColor="#F8D3B6" stopOpacity={0.15} />
        <stop offset={0.28} stopColor="#FADCC1" stopOpacity={0.38} />
        <stop offset={0.45} stopColor="#FCE3CA" stopOpacity={0.56} />
        <stop offset={0.62} stopColor="#FDE9D1" stopOpacity={0.71} />
        <stop offset={0.76} stopColor="#FEEDD6" stopOpacity={0.81} />
        <stop offset={0.9} stopColor="#FEF0D9" stopOpacity={0.88} />
        <stop offset={1} stopColor="#FFF1DB" stopOpacity={0.9} />
      </linearGradient>
      <linearGradient
        id="paint9_linear_4181_15266"
        x1={45.8243}
        y1={80.0528}
        x2={45.8243}
        y2={77.3405}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7CEAF" stopOpacity={0} />
        <stop offset={0.11} stopColor="#F8D3B6" stopOpacity={0.15} />
        <stop offset={0.28} stopColor="#FADCC1" stopOpacity={0.38} />
        <stop offset={0.45} stopColor="#FCE3CA" stopOpacity={0.56} />
        <stop offset={0.62} stopColor="#FDE9D1" stopOpacity={0.71} />
        <stop offset={0.76} stopColor="#FEEDD6" stopOpacity={0.81} />
        <stop offset={0.9} stopColor="#FEF0D9" stopOpacity={0.88} />
        <stop offset={1} stopColor="#FFF1DB" stopOpacity={0.9} />
      </linearGradient>
      <linearGradient
        id="paint10_linear_4181_15266"
        x1={39.3075}
        y1={87.2762}
        x2={38.9573}
        y2={85.0338}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7CEAF" stopOpacity={0} />
        <stop offset={0.11} stopColor="#F8D3B6" stopOpacity={0.15} />
        <stop offset={0.28} stopColor="#FADCC1" stopOpacity={0.38} />
        <stop offset={0.45} stopColor="#FCE3CA" stopOpacity={0.56} />
        <stop offset={0.62} stopColor="#FDE9D1" stopOpacity={0.71} />
        <stop offset={0.76} stopColor="#FEEDD6" stopOpacity={0.81} />
        <stop offset={0.9} stopColor="#FEF0D9" stopOpacity={0.88} />
        <stop offset={1} stopColor="#FFF1DB" stopOpacity={0.9} />
      </linearGradient>
      <linearGradient
        id="paint11_linear_4181_15266"
        x1={67.2737}
        y1={99.5705}
        x2={67.8054}
        y2={96.4425}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F7CEAF" stopOpacity={0} />
        <stop offset={0.11} stopColor="#F8D2B5" stopOpacity={0.14} />
        <stop offset={0.57} stopColor="#FDE5CE" stopOpacity={0.68} />
        <stop offset={0.77} stopColor="#FFEDD9" stopOpacity={0.9} />
      </linearGradient>
      <linearGradient
        id="paint12_linear_4181_15266"
        x1={68.947}
        y1={52.6693}
        x2={139.21}
        y2={107.415}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFECDA" />
        <stop offset={0.302083} stopColor="#FCE6D4" />
        <stop offset={0.99} stopColor="#E0AC98" />
      </linearGradient>
      <linearGradient
        id="paint13_linear_4181_15266"
        x1={64.8213}
        y1={51.3594}
        x2={95.405}
        y2={60.4874}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5FAF5" />
        <stop offset={1} stopColor="#FFECDA" />
      </linearGradient>
    </defs>
  </svg>
);

export default CommunityCongratulation;
