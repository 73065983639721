import Button from 'components/button';
import Modal from 'components/modal';
import { ModalType } from 'constant/home';
import useEscape from 'hooks/use-esc';
import { t } from 'i18next';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { NotifyProviderProps } from '../model';

export const NotifyContext = React.createContext<NotifyProviderProps | null>(
  null
);

const NotifyProvider = ({ children }: { children: ReactNode }) => {
  const [showProductsModal, setShowProductsModal] = useState<ModalType | null>(
    null
  );
  const [showNotifyModal, setShowNotifyModal] = useState<ModalType | null>(
    null
  );
  const [showCustomModal, setShowCustomModal] = useState<ModalType | null>(
    null
  );

  useEffect(() => {}, []);

  const closeProductsModal = () => {
    setShowProductsModal(null);
  };

  const closeNotifyModal = () => {
    setShowNotifyModal(null);
  };

  const closeCustomModal = () => {
    setShowCustomModal(null);
  };

  useEscape(() => {
    closeProductsModal();
    closeNotifyModal();
    closeCustomModal();
  });

  const contextValue = useMemo(() => {
    return {
      showProductsModal,
      showNotifyModal,
      showCustomModal,
      setShowProductsModal,
      setShowNotifyModal,
      setShowCustomModal,
    };
  }, [
    showProductsModal,
    showNotifyModal,
    showCustomModal,
    setShowProductsModal,
    setShowNotifyModal,
    setShowCustomModal,
  ]);

  return (
    <NotifyContext.Provider value={contextValue}>
      {children}
      <Modal
        key={"productsModal"}
        isShow={!!showProductsModal}
        isShowCloseIcon
        title={`zBusiness ${showProductsModal?.title}`}
        body={showProductsModal?.description}
        footer={
          showProductsModal?.okBtn
            ? [showProductsModal.okBtn]
            : [
                <Button block onClick={closeProductsModal}>
                  Đóng
                </Button>,
              ]
        }
        onClose={closeProductsModal}
      />
      <Modal
        key={"notifyModal"}
        isShow={!!showNotifyModal}
        isShowCloseIcon
        title={showNotifyModal?.title}
        body={showNotifyModal?.description}
        style={{ width: "400px" }}
        footer={
          showNotifyModal?.okBtn
            ? [showNotifyModal.okBtn]
            : [
                <Button
                  size="large"
                  type="primary"
                  block
                  onClick={closeNotifyModal}
                >
                  {t("understand")}
                </Button>,
              ]
        }
        onClose={closeNotifyModal}
      />
      <Modal
        key={"customModal"}
        isShow={!!showCustomModal}
        isShowCloseIcon
        compact
        title={showCustomModal?.title}
        body={showCustomModal?.description}
        style={{ width: "400px" }}
        onClose={closeCustomModal}
        footer={
          showCustomModal?.okBtn
            ? Array.isArray(showCustomModal.okBtn)
              ? showCustomModal.okBtn
              : [showCustomModal.okBtn]
            : undefined
        }
      />
    </NotifyContext.Provider>
  );
};

export default NotifyProvider;
