import Button from "components/button";
import Image from "components/image";
import Label from "components/label";
import Modal from "components/modal";
import Spin from "components/spin";
import Text from "components/text";
import { Z_BUSINESS } from "constant/home";
import { useAppSelector } from "hooks";
import useNotify from "hooks/useNotify";
import { useCallback, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import HomeServices from "services/home";
import { CODE } from "services/http-common";
import { upgradeProModalState } from "states/upgradeProModalState";
import { RootState } from "store";
import loginModal from "utils/login-modal";
import { ProductI } from "z-types/home";

const packageModalStyle = "package-modal";

export const UpgradeProModal: React.FC = () => {
  const [productSelected, setProductSelected] = useState<ProductI | null>(null);
  const isRequestPaymentRef = useRef<boolean>(false);
  const [isRequestPayment, setIsRequestPayment] = useState<boolean>(false);
  const { profile } = useAppSelector((state: RootState) => state.profile);
  const { openModal } = loginModal();
  const [info, setInfo] = useRecoilState(upgradeProModalState);
  const { setShowNotifyModal } = useNotify();

  const { flow = "Onboarding", source = "landing-page" } = info.data || {};

  const closeModal = () => {
    setInfo({ ...info, visible: false });
  };

  let products: ProductI[] = [];

  const proPackage = Z_BUSINESS.packages.find((pkg) => pkg.packageId === "pro");
  if (proPackage) {
    products = proPackage.products || [];
  }

  const onSelectProduct = useCallback(
    (product: ProductI) => {
      if (productSelected?.productId !== product.productId) {
        setProductSelected(product);
      }
    },
    [productSelected]
  );

  const handleBuyProduct = useCallback(async () => {
    if (isRequestPaymentRef?.current) return;

    isRequestPaymentRef.current = true;
    setIsRequestPayment(true);

    if (!productSelected) return;

    const { productId } = productSelected;

    HomeServices.trackingLog({
      flow: "Payment",
      action: "click_button_choose_package",
      params: JSON.stringify({
        product_id: productId,
        type: "subscription",
        source,
      }),
    });

    try {
      const requestPaymentResp = await HomeServices.requestProductPayment({
        productId,
        userId: profile?.userId || "",
      });

      closeModal();

      if (requestPaymentResp?.error_code !== CODE.SUCCESS) {
        isRequestPaymentRef.current = false;
        setIsRequestPayment(false);

        if (
          requestPaymentResp?.error_code === -200 &&
          requestPaymentResp.data
        ) {
          onLogin(requestPaymentResp.data);
          return;
        }

        HomeServices.trackingLog({
          flow: "Payment",
          action: "click_button_choose_package_fail",
          params: JSON.stringify({
            fail_reason: `${requestPaymentResp?.error_code}`,
            type: "subscription",
            source,
          }),
        });

        handleErrorNotification(requestPaymentResp?.error_code);
        return;
      }

      isRequestPaymentRef.current = false;
      setIsRequestPayment(false);

      window.location.href = requestPaymentResp.data;
    } catch (error) {
      closeModal();
      HomeServices.trackingLog({
        flow: "Payment",
        action: "click_button_choose_package_fail",
        params: JSON.stringify({
          fail_reason: `unknown_error`,
          error,
          type: "subscription",
          source,
        }),
      });
      setShowNotifyModal({
        description: (
          <div className="notify-modal">
            <Image
              srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
              width={160}
              height={160}
            />
            <Text
              center
              size="xlg-24"
              className="notify-modal__title"
              weight="three-quarters-bold"
            >
              Đã có lỗi xảy ra!!!
            </Text>
            <Text center size="xlg-18">
              Vui lòng thử lại.
            </Text>
          </div>
        ),
      });
      isRequestPaymentRef.current = false;
      setIsRequestPayment(false);
    }
  }, [productSelected, profile, isRequestPayment]);

  const handleErrorNotification = (errorCode: number) => {
    switch (errorCode) {
      case -2001:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Nâng cấp gói zBusiness không thành công
              </Text>
              <Text center size="xlg-18">
                Tài khoản của bạn chưa đủ điều kiện để thực hiện việc nâng cấp
                lên gói zBusiness
              </Text>
            </div>
          ),
        });
        break;
      case -2003:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đăng ký không thành công
              </Text>
              <Text center size="xlg-18">
                Bạn có thể chờ hết hạn gói hiện tại để đăng ký gói này
              </Text>
            </div>
          ),
        });
        break;
      case -2004:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/25/zds_il_user_account_ekyc_warning_1711354288375_317079.png"
                width={220}
                height={220}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Vui lòng thực hiện định danh tài khoản Zalo để tiếp tục
              </Text>
              <Text center size="xlg-18">
                Bạn cần mở ứng dụng Zalo trên thiết bị di động, kiểm tra tin
                nhắn từ <strong>OA Zalo Định Danh Tài Khoản</strong> và làm theo
                hướng dẫn.
              </Text>
            </div>
          ),
        });
        break;
      case -2005:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/25/zds_il_user_account_ekyc_warning_1711354288375_317079.png"
                width={220}
                height={220}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Vui lòng thực hiện lại định danh tài khoản Zalo để tiếp tục
              </Text>
              <Text center size="xlg-18">
                Bạn cần mở ứng dụng Zalo trên thiết bị di động, kiểm tra tin
                nhắn từ <strong>OA Zalo Định Danh Tài Khoản</strong> và làm theo
                hướng dẫn.
              </Text>
            </div>
          ),
        });
        break;
      case -2008:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Nâng cấp gói zBusiness không thành công
              </Text>
              <Text center size="xlg-18">
                Tài khoản của bạn đang sử dụng dịch vụ zStyle nên chưa thể nâng
                cấp zBusiness. Chúng tôi sẽ hỗ trợ trong thời gian sớm nhất.
              </Text>
            </div>
          ),
        });
        break;

      case -2009:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đăng ký không thành công
              </Text>
              <Text center size="xlg-18">
                Bạn không nằm trong danh sách giới hạn được trải nghiệm chương
                trình
              </Text>
            </div>
          ),
        });
        break;
      case -2010:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Thực hiện không thành công
              </Text>
              <Text center size="xlg-18">
                Bạn đã mua gói dùng thử zBusiness Pro trước đó
              </Text>
            </div>
          ),
        });
        break;
      case -2013:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Thực hiện không thành công
              </Text>
              <Text center size="xlg-18">
                Bạn đã mua gói ưu đãi zBusiness Pro trước đó
              </Text>
            </div>
          ),
        });
        break;
      default:
        setShowNotifyModal({
          description: (
            <div className="notify-modal">
              <Image
                srcImage="https://res-zalo.zadn.vn/upload/media/2024/3/15/error_notification_1710486895732_734768.svg"
                width={160}
                height={160}
              />
              <Text
                center
                size="xlg-24"
                className="notify-modal__title"
                weight="three-quarters-bold"
              >
                Đã có lỗi xảy ra!!!
              </Text>
              <Text center size="xlg-18">
                Vui lòng thử lại.
              </Text>
            </div>
          ),
        });
        break;
    }
  };

  const onLogin = (redirect: string): void => {
    openModal({
      title: "Đăng nhập để tiếp tục",
      description: "Vui lòng đăng nhập tài khoản Zalo của bạn để tiếp tục.",
      okText: "Đăng nhập",
      onOk: async function () {
        const params = {
          from: "choose_package",
          source,
        };
        HomeServices.trackingLog({
          flow: flow || "Onboarding",
          action: "click_button_sign_in",
          params: JSON.stringify(params),
        });

        window.location.href = redirect
          ? `${import.meta.env.VITE_ZBOX_LOGIN}?redirect=${redirect}`
          : import.meta.env.VITE_ZBOX_LOGIN!;
      },
    });
  };

  return (
    <Modal
      key={"UpgradeProModal"}
      isShow={info.visible}
      isShowCloseIcon
      compact
      title={"zBusiness Pro"}
      body={
        <div className={packageModalStyle}>
          <div className={`${packageModalStyle}__selection`}>
            <Text size="xlg-20" weight="semi-bold">
              Chọn gói để nâng cấp
            </Text>
            {products.map((product: ProductI) => (
              <div
                key={product.productId}
                className={
                  productSelected?.productId === product.productId
                    ? `${packageModalStyle}__selection__option ${packageModalStyle}__selection__option--activate`
                    : `${packageModalStyle}__selection__option`
                }
                onClick={() => onSelectProduct(product)}
              >
                <div
                  className={`${packageModalStyle}__selection__option__left`}
                >
                  <Text
                    color="grey"
                    weight="semi-bold"
                  >{`${product.duration} tháng`}</Text>
                  <div
                    className={`${packageModalStyle}__selection__option__left__price`}
                  >
                    <Text
                      size="xlg-24"
                      weight="semi-bold"
                      color={
                        productSelected?.productId === product.productId
                          ? "primary"
                          : undefined
                      }
                    >
                      {product.price}
                    </Text>
                    {product.savePercent && (
                      <Label
                        text={product.savePercent}
                        type="sale"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      />
                    )}
                  </div>
                  {product.promotionText && (
                    <Text color="dove-gray" weight="semi-bold">
                      {product.promotionText}
                    </Text>
                  )}
                </div>
                <Image
                  srcImage={
                    productSelected?.productId === product.productId
                      ? "https://res-zalo.zadn.vn/upload/media/2023/10/26/icon_blue_radio_1698310463932_565835.svg"
                      : "https://res-zalo.zadn.vn/upload/media/2023/10/26/icon_radio_1698310532882_576586.svg"
                  }
                />
              </div>
            ))}
          </div>
        </div>
      }
      style={{ width: "400px" }}
      onClose={closeModal}
      footer={[
        <Button
          type="primary"
          size="large"
          block
          onClick={handleBuyProduct}
          key={"buy_now"}
        >
          {isRequestPaymentRef.current ? <Spin isSmall /> : `Đăng ký ngay`}
        </Button>,
      ]}
    />
  );
};
