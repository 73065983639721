const Phone = () => (
  <svg width={142} height={129} viewBox="0 0 142 129" fill="none">
    <path
      opacity={0.3}
      d="M141.509 87.1526C141.509 96.7359 140.246 105.956 137.909 114.576H4.09386C1.75408 105.956 0.490723 96.7359 0.490723 87.1526C0.490723 39.2945 32.0656 0.5 71.0001 0.5C109.935 0.5 141.509 39.2945 141.509 87.1526Z"
      fill="url(#paint0_linear_4254_15851)"
    />
    <path
      d="M86.8827 123.21H51.0755C49.5743 123.21 48.1354 122.475 47.0761 121.167L46.0791 119.815L46.4239 117.815L46.3766 114.951L50.478 33.2123C50.651 30.0651 51.4974 29.9626 54.0641 29.9626L89.0523 29.2925L91.6836 29.6597L93.079 30.3436L94.8365 32.5926C95.958 33.9038 96.5455 35.7612 96.441 37.6583L92.077 117.24C91.8927 120.599 89.6211 123.21 86.8814 123.21H86.8827Z"
      fill="#23479E"
    />
    <path
      d="M85.15 121.441H49.2918C46.6641 121.441 44.5867 118.704 44.7684 115.482L49.3764 33.7494C49.5407 30.8272 51.5173 28.5613 53.8998 28.5613H89.7593C92.3869 28.5613 94.4644 31.2984 94.2826 34.5205L89.6746 116.253C89.5103 119.175 87.5337 121.441 85.1513 121.441H85.15Z"
      fill="url(#paint1_linear_4254_15851)"
    />
    <path
      d="M91.9738 34.9673L87.082 115.453C86.9937 116.929 85.9917 118.072 84.7855 118.072H49.3729C48.0335 118.072 46.9767 116.671 47.0763 115.032L51.9681 34.5466C52.0565 33.0701 53.0585 31.9272 54.2647 31.9272H66.0672L65.9863 33.2124C65.9278 34.2436 66.5937 35.1233 67.4352 35.1233H76.9213C77.6856 35.1233 78.3216 34.3966 78.3739 33.4603L78.4685 31.9272H89.6748C91.0166 31.9272 92.0709 33.3256 91.9714 34.9673H91.9738Z"
      fill="url(#paint2_linear_4254_15851)"
    />
    <path
      opacity={0.53}
      d="M75.126 31.9058H68.9534C68.6598 31.9058 68.4219 32.1983 68.4219 32.5591C68.4219 32.9199 68.6598 33.2124 68.9534 33.2124H75.126C75.4195 33.2124 75.6575 32.9199 75.6575 32.5591C75.6575 32.1983 75.4195 31.9058 75.126 31.9058Z"
      fill="#23479E"
    />
    <path
      d="M27.8755 46.3984C27.8789 46.0021 28.108 45.6594 28.4261 45.548C31.7671 44.3797 33.747 41.9894 34.7182 37.9601C34.8105 37.5803 35.091 37.3037 35.4158 37.2996C35.7441 37.2955 36.0245 37.5638 36.11 37.9436C37.0128 41.9563 38.9449 44.2888 42.2688 45.3787C42.5834 45.4819 42.8057 45.8204 42.8023 46.2168C42.7989 46.6089 42.5697 46.9475 42.2551 47.0589C38.9141 48.2314 36.9376 50.6175 35.9698 54.655C35.8775 55.039 35.5937 55.3156 35.2654 55.3197C34.9371 55.3238 34.6601 55.0555 34.5746 54.6715C33.6752 50.663 31.7363 48.3222 28.4158 47.2365C28.1012 47.1332 27.8755 46.7989 27.8789 46.4025L27.8755 46.3984Z"
      fill="#FFD488"
    />
    <path
      d="M106.747 94.3171C106.751 93.8976 106.993 93.535 107.33 93.417C110.866 92.1805 112.961 89.6506 113.989 85.3862C114.087 84.9842 114.384 84.6914 114.728 84.6871C115.075 84.6827 115.372 84.9667 115.462 85.3687C116.418 89.6157 118.463 92.0844 121.981 93.2379C122.314 93.3471 122.549 93.7054 122.545 94.1249C122.542 94.5399 122.299 94.8982 121.966 95.0162C118.43 96.2571 116.338 98.7826 115.314 103.056C115.216 103.462 114.916 103.755 114.568 103.759C114.221 103.764 113.928 103.48 113.837 103.073C112.885 98.8306 110.833 96.3532 107.319 95.2041C106.986 95.0948 106.747 94.7409 106.751 94.3215L106.747 94.3171Z"
      fill="#FFB93E"
    />
    <path
      d="M19.9834 62.3493C19.9852 62.1397 20.1064 61.9586 20.2745 61.8996C22.041 61.2819 23.0879 60.018 23.6014 57.8876C23.6503 57.6868 23.7985 57.5405 23.9703 57.5384C24.1439 57.5362 24.2922 57.6781 24.3374 57.8789C24.8147 60.0006 25.8363 61.2339 27.5938 61.8101C27.7601 61.8647 27.8777 62.0437 27.8758 62.2532C27.874 62.4606 27.7529 62.6396 27.5865 62.6985C25.82 63.3185 24.7749 64.5801 24.2632 66.7149C24.2144 66.9179 24.0643 67.0642 23.8908 67.0664C23.7172 67.0685 23.5707 66.9267 23.5255 66.7237C23.05 64.6041 22.0248 63.3665 20.2691 62.7924C20.1027 62.7378 19.9834 62.561 19.9852 62.3515L19.9834 62.3493Z"
      fill="#FFB93E"
    />
    <path
      d="M100.982 121.609C100.982 121.302 101.161 121.036 101.406 120.951C103.987 120.048 105.515 118.203 106.267 115.087C106.338 114.792 106.554 114.582 106.808 114.576C107.062 114.576 107.278 114.781 107.344 115.076C108.04 118.181 109.535 119.98 112.102 120.82C112.347 120.899 112.516 121.161 112.516 121.467C112.516 121.774 112.337 122.035 112.093 122.12C109.511 123.028 107.983 124.873 107.236 127.989C107.165 128.284 106.944 128.5 106.69 128.5C106.436 128.5 106.22 128.295 106.154 128C105.458 124.901 103.959 123.09 101.391 122.25C101.147 122.171 100.973 121.91 100.978 121.603L100.982 121.609Z"
      fill="#FFD488"
    />
    <path
      d="M120.32 110.649C120.32 110.429 120.449 110.237 120.625 110.176C122.481 109.527 123.579 108.201 124.12 105.96C124.171 105.748 124.327 105.597 124.509 105.593C124.692 105.593 124.847 105.739 124.894 105.952C125.395 108.184 126.47 109.478 128.316 110.082C128.492 110.139 128.613 110.327 128.613 110.547C128.613 110.768 128.485 110.955 128.309 111.017C126.453 111.67 125.354 112.996 124.817 115.237C124.766 115.449 124.607 115.604 124.425 115.604C124.242 115.604 124.086 115.457 124.039 115.245C123.539 113.016 122.46 111.715 120.614 111.11C120.439 111.053 120.314 110.866 120.317 110.645L120.32 110.649Z"
      fill="#FFD488"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4254_15851"
        x1={71.0001}
        y1={0.5}
        x2={71.0001}
        y2={114.576}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#A0DDFF" />
        <stop offset={1} stopColor="white" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4254_15851"
        x1={67.018}
        y1={122.578}
        x2={77.2715}
        y2={-6.22464}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.02} stopColor="#296AFF" />
        <stop offset={0.21} stopColor="#2B6CFF" />
        <stop offset={0.35} stopColor="#3572FF" />
        <stop offset={0.48} stopColor="#457DFF" />
        <stop offset={0.6} stopColor="#5C8DFF" />
        <stop offset={0.71} stopColor="#79A2FF" />
        <stop offset={0.81} stopColor="#9DBBFF" />
        <stop offset={0.91} stopColor="#C7D8FF" />
        <stop offset={1} stopColor="#F3F7FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4254_15851"
        x1={81.0376}
        y1={109.882}
        x2={44.6814}
        y2={36.9669}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C9E2FF" />
        <stop offset={0.02} stopColor="#CAE3FF" />
        <stop offset={0.4} stopColor="#E5F1FF" />
        <stop offset={0.73} stopColor="#F6FAFF" />
        <stop offset={0.98} stopColor="#FCFEFF" />
      </linearGradient>
    </defs>
  </svg>
);

export default Phone;
