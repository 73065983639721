import "./style.scss";

import Layout from "components/layout";
import PackagesBenefit from "components/packages-benefit";
import Section from "components/section";
import Spin from "components/spin";
import Text from "components/text";
import { PC_AGENT } from "constant/home";
import { useAppSelector } from "hooks";
import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import home from "services/home";
import { RootState, store } from "store";
import { onFetchProfile } from "store/profile";
import { setTrackingReferer } from "utils/helper";

const owlClass = "another-paywall";
const source = "paywall_business_pro";

const AnotherPaywall: React.FC = () => {
  const navigate = useNavigate();
  const { profile, isLoadingProfile } = useAppSelector(
    (state: RootState) => state.profile
  );
  const initLogin = useRef<boolean>(false);
  const handleNavigate = (path: string, func?: () => void) => {
    navigate(path);
    func?.();
  };

  useEffect(() => {
    if (!initLogin.current) {
      setTrackingReferer();
      home.trackingLog({
        flow: "Onboard Paywall",
        action: "visit_paywall_business",
        params: JSON.stringify({ source }),
      });
      store.dispatch(
        onFetchProfile(`${import.meta.env.VITE_DOMAIN}/nang-cap-business-pro`, {
          flow: "Onboard Paywall",
          action: "sign_in_success",
          params: JSON.stringify({ source }),
        })
      );
      initLogin.current = true;
    }
  });

  const onClickServiceAgreement = () => {
    home.trackingLog({
      flow: "Onboarding Paywall",
      action: "click_service_agreement",
      params: JSON.stringify({ source }),
    });
    handleNavigate("/service-agreement");
  };

  if (
    (isLoadingProfile || !profile?.userId) &&
    window.navigator.userAgent.includes(PC_AGENT)
  ) {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <div
          style={{
            position: "fixed",
            width: "100vh",
            height: "100vh",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        <Section
          name="paywall"
          style={{
            position: "sticky",
            top: 0,
            zIndex: 9,
            background: "white",
          }}
          title={
            <Text size="xlg-28" weight="bold">
              Kinh doanh hiệu quả trên Zalo cùng <br />
              zBusiness Pro
            </Text>
          }
        ></Section>
        <div className={owlClass}>
          <Section style={{ width: "100%", padding: 0 }}>
            <PackagesBenefit source={source} />
          </Section>
          <div className={`${owlClass}_service-agreement`}>
            Khi sử dụng dịch vụ, bạn cũng đồng ý với{" "}
            <span onClick={onClickServiceAgreement}>Thoả thuận sử dụng</span>{" "}
            dịch vụ zBusiness.
          </div>
        </div>
      </Layout>
    </>
  );
};

export default AnotherPaywall;
