import './index.scss';
import './i18n.ts';

import ReactDOM from 'react-dom/client';

import * as Sentry from '@sentry/react';

import App from './App.tsx';

Sentry.init({
  enabled: import.meta.env.MODE !== "development",
  release: import.meta.env.VITE_SENTRY_RELEASE,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  dsn: "https://25eb14e02123756323cdba9efa264a53@sentry-mini.zalo.me/46",
  replaysOnErrorSampleRate: 1.0,
  integrations: [Sentry.replayIntegration()],
});

window.addEventListener("vite:preloadError", () => {
  window.location.reload();
});

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
